import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { IUser } from "../../interfaces/auth.interface";
import { AuthState, logout } from "../../store/auth/auth.slice";
import {
  AssessmentTypeState,
  listAssessmentType,
} from "../../store/assessments/assessmentType.slice";
import {
  IAssessment,
  IAssessmentType,
} from "../../interfaces/assessment.interface";
import {
  clearAssessment,
  clearCustomAssessment,
  createAssessment,
  getAssessmentAverage,
  listAssessment,
  searchAssessment,
  setAssessment,
  setCustomAssessment,
} from "../../store/assessments/assessment.slice";
import { useNavigate } from "react-router-dom";
import Header from "../templates/header.component";

const Home: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const defaultProfilePhoto =
    process.env.PUBLIC_URL + "/images/default-user.jpg";
  const heroArt = process.env.PUBLIC_URL + "/images/hero.svg";
  const [canTakeAssessment, setCanTakeAssessment] = useState(false);
  const user: IUser = useSelector(
    (state: RootState) => state.auth.user as IUser
  );
  const assessment: IAssessment = useSelector(
    (state: RootState) => state.assessment.assessment as IAssessment
  );
  const assessmentList: IAssessment[] = useSelector(
    (state: RootState) => state.assessment.assessmentList as IAssessment[]
  );
  const overallAverage: number = useSelector(
    (state: RootState) => state.assessment.overallAverage as number
  );

  useEffect(() => {
    if (user) dispatch(listAssessmentType());
  }, [dispatch, user]);

  const assessmentTypeList = useSelector<RootState, IAssessmentType[]>(
    (state) => state.assessmentType.assessmentTypeList as IAssessmentType[]
  );
  const featuredAssessmentType = assessmentTypeList?.[0];

  // TODO: set up maturity score in a "maturity score slice" in redux or determine where it should be stored
  // const maturityScore = useSelector((state: RootState) => state.maturityScore);

  const maturityScore: number = 0;

  // useEffect(() => {
  //   if (assessment) {
  //     navigate("/assessment/category");
  //   }
  // }, [assessment, navigate]);

  //TODO: add checker for idle users on assessment

  useEffect(() => {
    console.log("featuredAssessmentType", featuredAssessmentType);
    if (featuredAssessmentType && user) {
      if (featuredAssessmentType.type?.toLowerCase() === "scoring")
        dispatch(
          getAssessmentAverage({
            assessment_type_id: featuredAssessmentType.assessment_type_id,
            company_id: user.company_id as string,
          })
        );
      dispatch(
        searchAssessment({
          assessment_type_id: featuredAssessmentType.assessment_type_id,
          company_id: user.company_id,
          year: getYear(),
          quarter: "Q" + getYearQuarter(),
          limit: 1,
        })
      );
    } else {
      dispatch(clearCustomAssessment());
    }
  }, [featuredAssessmentType, user]);

  useEffect(() => {
    if (assessmentList) {
      console.log("ASSESSMENTS", assessmentList);
      if (assessmentList.length > 0) {
        const status = assessmentList[0].status;
        if (status === "completed" || status === "locked") {
          console.log("ASSESSMENT LOCKED");
          setCanTakeAssessment(false);
          //TODO: if locked, verify if current use isthe user answering the assessment
          //TODO: add prompt that current assessment for the quarter is completed/locked
        } else {
          console.log("HAS EXISTING ASSESSMENT");
          dispatch(setAssessment(assessmentList[0]));
          setCanTakeAssessment(true);
        }
      } else {
        console.log("WILL CREATE NEW ASSESSMENT");
        setCanTakeAssessment(true);
      }
    }
  }, [assessmentList]);

  const getYearQuarter = (): number => {
    const currentMonth = new Date().getMonth();
    return Math.floor(currentMonth / 3) + 1;
  };

  const getYear = (): number => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };

  const takeAssessment = () => {
    //TODO: lock assessment also
    if (canTakeAssessment) {
      console.log("THIS HAS ASSESSMENT?", assessment);
      if (!assessment) {
        dispatch(
          createAssessment({
            user: user,
            assessmentType: featuredAssessmentType,
          })
        ).then(() => {
          navigate("/assessment/category");
        });
      } else {
        navigate("/assessment/category");
      }
    }
  };

  const goToAssessmentTypes = () => {
    dispatch(clearAssessment());
    navigate("/assessment-type");
  };

  const goToPastAssessment = () => {
    navigate("/past-assessments");
  };

  const goToEPub = () => {
    navigate("/epub");
  };

  return (
    <div className="relative min-h-screen">
      <Header />

      <div className="flex flex-col lg:flex-row-reverse justify-center items-center lg:items-start">
        <div className="w-full lg:w-1/2 flex justify-center items-center">
          <img
            src={heroArt}
            alt=""
            className="w-2/4 h-2/4 lg:h-full lg:w-full"
          />
        </div>
        <div className="w-full lg:w-1/2 px-20 lg:pl-20 text-left my-auto">
          <span className="text-sm lg:text-base py-2">
            Legal Operations Assessment Tools
          </span>

          <p className="text-black text-3xl lg:text-5xl font-bold text-left">
            We focus on{" "}
            <span className="text-cloc-blue font-bold">Operational</span>{" "}
            assessment
          </p>

          <p className="text-sm lg:text-base">
            An ever-evolving and vital role within the legal department, CLOC
            explains the role of Legal Operations within a team that plans for
            challenges before they arrive.
          </p>
        </div>
      </div>

      <div className="space-y-4 flex flex-col-reverse lg:flex-row pb-4 items-center lg:items-start">
        <div className="w-full lg:w-1/2 px-20 lg:pl-20 text-left my-auto">
          {/* Assessment Buttons */}
          <div className="text-left">
            <p className="text-xl">
              Ready to take the{" "}
              <span className="text-cloc-orange font-bold">
                {featuredAssessmentType?.name}
              </span>
              ?
            </p>
          </div>
          <div className="pt-2">
            <button
              className="my-2 px-4 py-2 bg-cloc-orange text-white rounded-full mr-3 disabled:bg-cloc-orange-alt disabled:cursor-not-allowed disabled:text-gray-200"
              disabled={!canTakeAssessment}
              onClick={() => takeAssessment()}
            >
              Take Assessment
            </button>
            <button
              className="my-2 px-4 py-2 bg-cloc-blue text-white rounded-full mr-3"
              onClick={() => goToPastAssessment()}
            >
              Past Assessments
            </button>
            <button
              className="my-2 px-4 py-2 bg-cloc-blue text-white rounded-full mr-3"
              onClick={() => goToAssessmentTypes()}
            >
              Other Assessment Types
            </button>
            <button
              className="my-2 px-4 py-2 bg-cloc-blue text-white rounded-full mr-3"
              onClick={() => goToEPub()}
            >
              e-Pub
            </button>
          </div>
        </div>
        <div
          className={`w-full lg:w-1/2 flex justify-start lg:justify-end pl-20 lg:pr-20 ${
            overallAverage !== null ? "block" : "none"
          }`}
        >
          {/* Maturity Score */}
          <p className="text-xl font-bold my-auto pr-4">
            My Company's Maturity Score:
          </p>
          <span className="text-cloc-orange font-bold text-5xl my-auto">
            {overallAverage?.toFixed(2)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Home;
