import { ICriterion } from "../interfaces/assessment.interface";

export const evaluateScore = (
  score: number,
  criteria: ICriterion[]
): ICriterion | null => {
  for (const criterion of criteria) {
    const condition = criterion.condition;
    const value = parseFloat(criterion.score);
    if (
      (condition === "<" && score < value) ||
      (condition === "<=" && score <= value) ||
      (condition === ">" && score > value) ||
      (condition === ">=" && score >= value) ||
      (condition === "==" && score === value) ||
      (condition === "!=" && score !== value)
    ) {
      return criterion;
    }
  }
  return null;
};

export const deepEqualCriteria = (
  arr1: ICriterion[],
  arr2: ICriterion[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const criterion1 = arr1[i];
    const criterion2 = arr2[i];

    if (
      criterion1.criterion_id !== criterion2.criterion_id ||
      criterion1.assessment_type_id !== criterion2.assessment_type_id ||
      criterion1.name !== criterion2.name ||
      criterion1.condition !== criterion2.condition ||
      criterion1.score !== criterion2.score ||
      criterion1.order !== criterion2.order
    ) {
      return false;
    }
  }

  return true;
};
