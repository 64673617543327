// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { IOption } from "../../interfaces/assessment.interface";
import { AxiosError } from "axios";

export interface OptionState {
  loading: boolean;
  error: string | null;
  option: IOption | null;
  optionList: IOption[] | null;
}

const initialState: OptionState = {
  loading: false,
  error: null,
  option: null,
  optionList: null,
};

export const createOption = createAsyncThunk(
  "option/create",
  async (data: IOption, { rejectWithValue }) => {
    try {
      const response = await axios.post("/options", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateOption = createAsyncThunk(
  "option/update",
  async (data: IOption, { rejectWithValue }) => {
    try {
      const response = await axios.put("/options/" + data.option_id, data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveOption = createAsyncThunk(
  "option/archive",
  async (data: IOption, { rejectWithValue }) => {
    try {
      const response = await axios.delete("/options/" + data.option_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listOption = createAsyncThunk(
  "option/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/options");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchOption = createAsyncThunk(
  "option/fetch",
  async (data: IOption, { rejectWithValue }) => {
    try {
      const response = await axios.get("/options/" + data.option_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const optionSlice = createSlice({
  name: "option",
  initialState,
  reducers: {
    setOption: (state, { payload }) => {
      state.option = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOption.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.option = payload;
    });
    builder.addCase(createOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateOption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOption.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.option = payload;
    });
    builder.addCase(updateOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(archiveOption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveOption.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.option = payload;
    });
    builder.addCase(archiveOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchOption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOption.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.option = payload;
    });
    builder.addCase(fetchOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(listOption.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listOption.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.optionList = payload;
    });
    builder.addCase(listOption.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setOption } = optionSlice.actions;
export default optionSlice.reducer;
