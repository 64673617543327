// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { IAssessmentType } from "../../interfaces/assessment.interface";
import { AxiosError } from "axios";

export interface AssessmentTypeState {
  loading: boolean;
  error: string | null;
  assessmentType: IAssessmentType | null;
  assessmentTypeList: IAssessmentType[] | null;
}

const initialState: AssessmentTypeState = {
  loading: false,
  error: null,
  assessmentType: null,
  assessmentTypeList: null,
};

const sortAssessmentTypeList = (assessmentTypeList: IAssessmentType[]) => {
  const sortedAssessmentTypeList: IAssessmentType[] = JSON.parse(
    JSON.stringify(assessmentTypeList)
  ) as IAssessmentType[];

  sortedAssessmentTypeList.sort((a, b) => a.order - b.order);

  sortedAssessmentTypeList.forEach((assessmentType, index) => {
    sortedAssessmentTypeList[index] = sortAssessmentTypeData(assessmentType);
  });
  console.log("new assessment Type List", sortedAssessmentTypeList);

  return sortedAssessmentTypeList;
};

const sortAssessmentTypeData = (assessmentType: IAssessmentType) => {
  // Clone the assessment to avoid mutating the original object
  const sortedAssessmentType: IAssessmentType = JSON.parse(
    JSON.stringify(assessmentType)
  ) as IAssessmentType;

  // Sort criteria
  sortedAssessmentType.criteria?.sort((a, b) => a.order - b.order);

  // Sort categories
  sortedAssessmentType.categories?.sort((a, b) => a.order - b.order);

  // Sort questions within each category
  sortedAssessmentType.categories?.forEach((category) => {
    category.questions?.sort((a, b) => a.order - b.order);

    // Sort options within each question (if it's a Multiple Choice question)
    category.questions?.forEach((question) => {
      if (question.options) {
        question.options.sort((a, b) => a.order - b.order);
      }
    });
  });

  console.log("SORTED ASSESSMENT TYPE", sortedAssessmentType);

  return sortedAssessmentType;
};

export const upsertAssessmentType = createAsyncThunk(
  "assessmentType/upsert",
  async (data: IAssessmentType, { rejectWithValue }) => {
    try {
      const response = await axios.post("/assessment-types/upsert", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const orderAssessmentTypes = createAsyncThunk(
  "assessmentType/order",
  async (data: IAssessmentType[], { rejectWithValue }) => {
    try {
      const response = await axios.post("/assessment-types/order", {
        assessmentTypes: data,
      });
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const createAssessmentType = createAsyncThunk(
  "assessmentType/create",
  async (data: IAssessmentType, { rejectWithValue }) => {
    try {
      const response = await axios.post("/assessment-types", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateAssessmentType = createAsyncThunk(
  "assessmentType/update",
  async (data: IAssessmentType, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "/assessment-types/" + data.assessment_type_id,
        data
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveAssessmentType = createAsyncThunk(
  "assessmentType/archive",
  async (data: IAssessmentType, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        "/assessment-types/" + data.assessment_type_id
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listAssessmentType = createAsyncThunk(
  "assessmentType/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/assessment-types");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchAssessmentType = createAsyncThunk(
  "assessmentType/fetch",
  async (data: IAssessmentType, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "/assessment-types/" + data.assessment_type_id
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const assessmentTypeSlice = createSlice({
  name: "assessmentType",
  initialState,
  reducers: {
    setAssessmentType: (state, { payload }) => {
      state.assessmentType = payload;
    },
    setAssessmentTypeList: (state, { payload }) => {
      state.assessmentTypeList = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(upsertAssessmentType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(upsertAssessmentType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentType = sortAssessmentTypeData(payload);
    });
    builder.addCase(upsertAssessmentType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(orderAssessmentTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(orderAssessmentTypes.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentTypeList = sortAssessmentTypeList(payload);
    });
    builder.addCase(orderAssessmentTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createAssessmentType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAssessmentType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentType = sortAssessmentTypeData(payload);
    });
    builder.addCase(createAssessmentType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateAssessmentType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAssessmentType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentType = sortAssessmentTypeData(payload);
    });
    builder.addCase(updateAssessmentType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(archiveAssessmentType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveAssessmentType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentType = sortAssessmentTypeData(payload);
    });
    builder.addCase(archiveAssessmentType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchAssessmentType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAssessmentType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentType = sortAssessmentTypeData(payload);
    });
    builder.addCase(fetchAssessmentType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(listAssessmentType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listAssessmentType.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.assessmentTypeList = sortAssessmentTypeList(payload);
    });
    builder.addCase(listAssessmentType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setAssessmentType, setAssessmentTypeList } =
  assessmentTypeSlice.actions;
export default assessmentTypeSlice.reducer;
