import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { noviCallback } from "../../store/auth/auth.slice";
// import { useHistory } from 'react-router-dom';
// import { setAuthToken } from '../features/auth/authSlice';

const NoviCallback: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code: string = params.get("code") as string;
    const state: string = params.get("state") as string;

    dispatch(noviCallback({ code, state })).then(() => navigate("/"));
  }, [dispatch, location.search]);

  return <div>Loading...</div>;
};

export default NoviCallback;
