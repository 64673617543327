import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { IRole } from "../../interfaces/auth.interface";
import { createRole, setRole, updateRole } from "../../store/auth/role.slice";
import Header from "../templates/header.component";

const RoleForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const role: IRole = useSelector(
    (state: RootState) => state.role.role as IRole
  );

  //Dropdown Change
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    dispatch(setRole({ ...role, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("role", role);
    if (role?.role_id) {
      dispatch(updateRole(role as IRole)).then(() => {
        //add success modal for success
        //catch error
        dispatch(setRole(null));
        navigate("/role");
      });
    } else {
      dispatch(createRole(role as IRole)).then(() => {
        //add success modal for success
        //catch error
        dispatch(setRole(null));
        navigate("/role");
      });
    }
  };

  const goBack = () => {
    dispatch(setRole(null));
    navigate("/role");
  };

  return (
    <form onSubmit={handleSubmit} className="p-4 space-y-4">
      <Header />
      <button
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={() => goBack()}
      >
        Go Back
      </button>
      <div>
        <label htmlFor="name" className="block">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={role?.name || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        />
      </div>
      {/* Access Select */}
      <div>
        <label htmlFor="organizationSize" className="block">
          Access
        </label>
        <select
          id="access"
          name="access"
          value={role?.access || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        >
          <option value="">Select Access</option>
          <option value="View">View</option>
          <option value="Default">Default</option>
          <option value="Full">Full</option>
        </select>
      </div>
      <div>
        <label htmlFor="order" className="block">
          Order
        </label>
        <input
          type="text"
          id="order"
          name="order"
          value={role?.order || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        />
      </div>
      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Save Role
      </button>
    </form>
  );
};

export default RoleForm;
