import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listCompany } from "../../store/company/company.slice"; // Make sure to import the correct action to list companies
import { AppDispatch, RootState } from "../../store/store";
import { Link, useNavigate } from "react-router-dom";
import { setUser, signup } from "../../store/auth/auth.slice";
import { listRole } from "../../store/auth/role.slice";

const SignUp: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { companies, user, roles } = useSelector((state: RootState) => ({
    companies: state.company.companyList,
    user: state.auth.user,
    roles: state.role.roleList,
  }));

  //TODO: handle role_id

  const [confirmPass, setConfirmPass] = useState<string>("");

  useEffect(() => {
    dispatch(listCompany());
    dispatch(listRole());
  }, [dispatch]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    dispatch(setUser({ ...user, [e.target.name]: e.target.value }));
  };

  const handleConfirmPassChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setConfirmPass(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("user", user);
    console.log("user.password", user?.password);
    console.log("confirmPass", confirmPass);
    if (user?.password !== confirmPass) {
      alert("Passwords do not match.");
      return;
    }
    // Dispatch your signup action here, using user directly
    console.log(user);
    //TODO: add logic for specifying role
    dispatch(
      signup({
        ...user,
        ["role_id"]: roles.find((role) => role.name == "Employee")
          ?.role_id as string,
      })
    ).then(() => navigate("/login"));
    //TODO: add logic for logging in after sign up
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-xs">
        <p className="text-xl pb-3 font-bold">Compass</p>
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          {/* First Name */}
          <div className="mb-4">
            <label
              htmlFor="first_name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={user?.first_name}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          {/* Last Name */}
          <div className="mb-4">
            <label
              htmlFor="last_name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={user?.last_name}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          {/* Email */}
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={user?.email}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          {/* Password */}
          <div className="mb-4">
            <label
              htmlFor="pass"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={user?.password}
              onChange={handleChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          {/* Confirm Password */}
          <div className="mb-4">
            <label
              htmlFor="confirmPass"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPass"
              name="confirmPass"
              value={confirmPass}
              onChange={handleConfirmPassChange}
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          {/* Company ID Select */}
          <div className="mb-4">
            <label
              htmlFor="company_id"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Company
            </label>
            <select
              id="company_id"
              name="company_id"
              value={user?.company_id}
              onChange={handleChange}
              required
              className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            >
              <option value="">Select a company</option>
              {companies.map((company) => (
                <option key={company.company_id} value={company.company_id}>
                  {company.name}
                </option>
              ))}
            </select>
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-cloc-orange text-white rounded-full"
          >
            Sign Up
          </button>
        </form>
        <Link
          to="/login"
          className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
        >
          Already have an account?
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
