import React, { useState, useEffect, FormEvent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IAssessmentType,
  ICategory,
  IQuestion,
  IOption,
  ICriterion,
  ITerminology,
} from "../../../interfaces/assessment.interface";
import { AppDispatch, RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import Header from "../../templates/header.component";
import CriteriaForm from "./criteriaForm.component";
import {
  setAssessmentType,
  upsertAssessmentType,
} from "../../../store/assessments/assessmentType.slice";
import { deepEqualCriteria } from "../../../util/criteriaEvaluator.util";
import { IoChevronBack } from "react-icons/io5";
import { FaSave } from "react-icons/fa";
import {
  deepEqualTerminologies,
  getTerm,
} from "../../../util/terminology.util";
import CategoriesForm from "./categoriesForm.component";
import TerminologiesForm from "./terminologiesForm.component";
import { deepEqualCategories } from "../../../util/category.util";
import { deepEqualAssessmentType } from "../../../util/assessmentType.util";
import Breadcrumb from "../../templates/breadcrumb.component";
import { setBreadcrumbs } from "../../../store/app/breadcrumbs.slice";

const AssessmentTypeForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const loading = useSelector(
    (state: RootState) => state.assessmentType.loading
  );
  const error = useSelector((state: RootState) => state.assessmentType.error);
  const assessmentType = useSelector(
    (state: RootState) => state.assessmentType.assessmentType
  );
  const prevCriteriaRef = useRef<ICriterion[]>(assessmentType?.criteria || []);
  const prevTerminologiesRef = useRef<ITerminology[]>(
    assessmentType?.terminologies || []
  );
  const prevCategoryRef = useRef<ICategory[]>(assessmentType?.categories || []);
  const prevAssessmentTypeRef = useRef<IAssessmentType | null>(assessmentType);
  const [activeTab, setActiveTab] = useState(1);
  const [hasChanges, setHasChanges] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { name: "Home", path: "/" },
        {
          name: "Assessment Types",
          path: "/assessment-type",
        },
        {
          name: "Form",
          path: "/assessment-type/form",
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!assessmentType) {
      dispatch(
        setAssessmentType({
          assessment_type_id: "",
          name: "",
          description: "",
          order: null,
          type: "scoring",
          categories: [],
          criteria: [],
          terminologies: [],
        })
      );
    }

    if (
      !deepEqualAssessmentType(
        prevAssessmentTypeRef.current as IAssessmentType,
        assessmentType as IAssessmentType
      )
    ) {
      setHasChanges(true);
      console.log("AssessmentType CHANGED");
      prevAssessmentTypeRef.current = assessmentType;
    } else setHasChanges(false);

    console.log("INITIAL assessment type", assessmentType);
  }, [assessmentType]);

  // Handle input changes for assessment type
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    console.log("new assessment type", value);
    console.log("new assessment type name", name);
    console.log("assessment type", assessmentType);
    if (name === "type" && value === "summary") {
      dispatch(
        setAssessmentType({ ...assessmentType, [name]: value, criteria: [] })
      );
    } else dispatch(setAssessmentType({ ...assessmentType, [name]: value }));
  };

  const handleCriteriaChange = (updatedCriteria: ICriterion[]) => {
    if (!deepEqualCriteria(updatedCriteria, prevCriteriaRef.current)) {
      console.log("CRITERIA CHANGED");
      prevCriteriaRef.current = updatedCriteria;
      dispatch(
        setAssessmentType({
          ...assessmentType,
          criteria: updatedCriteria,
        })
      );
    }
  };

  const handleTerminologiesChange = (updatedTerminologies: ITerminology[]) => {
    if (
      !deepEqualTerminologies(
        updatedTerminologies,
        prevTerminologiesRef.current
      )
    ) {
      console.log("TERMINOLOGIES CHANGED");
      prevTerminologiesRef.current = updatedTerminologies;
      dispatch(
        setAssessmentType({
          ...assessmentType,
          terminologies: updatedTerminologies,
        })
      );
    }
  };

  const handleCategoriesChange = (updatedCategories: ICategory[]) => {
    if (!deepEqualCategories(updatedCategories, prevCategoryRef.current)) {
      console.log("CATEGORIES CHANGED");
      prevCategoryRef.current = updatedCategories;
      dispatch(
        setAssessmentType({
          ...assessmentType,
          categories: updatedCategories,
        })
      );
    }
  };

  const handleTabClick = (id: number) => {
    setActiveTab(id);
  };

  const handleSubmit = (localAssessmentType: IAssessmentType) => {
    dispatch(upsertAssessmentType(localAssessmentType)).then((value) => {
      if (error) setModalMessage(error);
      else setModalMessage("Your changes have been saved.");

      // TODO: the modal is opened before messags are done saving in state
      // setIsModalOpen(true);
      // setHasChanges(false);
      prevAssessmentTypeRef.current = localAssessmentType;

      console.log("RESPONSE FROM UPSERT", value);
    });

    // Here, you can add your logic to handle the form submission,
    // like sending data to an API
  };

  useEffect(() => {
    if (modalMessage) {
      setIsModalOpen(true);
      setHasChanges(false);
    }
  }, [modalMessage]);

  const goBack = () => {
    setHasChanges(false);
    dispatch(setAssessmentType(null));
    navigate("/assessment-type");
  };

  // Render UI elements for categories, questions, and options
  return (
    <div className="">
      <Header />
      <div>
        <div className="px-4">
          <Breadcrumb />
        </div>
        <div className="px-4 pb-2 text-left flex flex-row justify-between">
          <div className="">
            <p className="text-3xl font-bold">Assessment Type Form</p>
          </div>
          <div className="my-auto flex justify-end">
            <div
              className={`flex my-auto mx-2 ${hasChanges ? "block" : "hidden"}`}
            >
              <p className="text-sm text-red-500 text-right font-light">
                Your changes have not been saved
              </p>
            </div>
            <button
              className="px-4 py-2 bg-cloc-orange text-white rounded-full mr-3 disabled:bg-cloc-orange-alt disabled:cursor-not-allowed disabled:text-gray-200"
              onClick={() => handleSubmit(assessmentType as IAssessmentType)}
              disabled={!hasChanges}
            >
              <p className="flex justify-center text-bold text-base">
                <span className="my-auto mx-1 relative bottom-0.25">
                  <FaSave />
                </span>
                Save
              </p>
            </button>
          </div>
        </div>
      </div>
      <div className="mx-4 p-8 border-t border-cloc-blue">
        <div className="flex border-b border-gray-300">
          <button
            key="1"
            onClick={() => handleTabClick(1)}
            className={`py-2 px-4 text-sm font-medium focus:outline-none ${
              activeTab === 1
                ? "border-b-2 border-blue-500 text-blue-500"
                : "text-gray-500 hover:text-blue-500"
            }`}
          >
            Form
          </button>
          <button
            key="2"
            onClick={() => handleTabClick(2)}
            className={`py-2 px-4 text-sm font-medium focus:outline-none ${
              activeTab === 2
                ? "border-b-2 border-blue-500 text-blue-500"
                : "text-gray-500 hover:text-blue-500"
            }`}
          >
            Advanced
          </button>
        </div>
        <div className="mt-4">
          {/* Form */}
          <div key="1" className={`${activeTab === 1 ? "block" : "hidden"}`}>
            <div className="p-2 flex flex-col justify-center items-start">
              {/* Assessment Type Details Card */}
              <div className="px-4 mb-2 bg-white shadow-md rounded-lg w-full">
                <div className="pt-2 pb-6">
                  <div className="text-left flex flex-row justify-between">
                    <div className="flex">
                      <p className="text-4xl font-bold">Info</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div>
                      <label htmlFor="name" className="block text-left">
                        Assessment Type Name:
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={assessmentType?.name}
                        onChange={handleInputChange}
                        className="block w-full p-2 border border-gray-300 rounded"
                      />
                    </div>
                    <div>
                      <label htmlFor="description" className="block text-left">
                        Description:
                      </label>
                      <textarea
                        id="description"
                        name="description"
                        value={assessmentType?.description}
                        onChange={handleInputChange}
                        className="block w-full p-2 border border-gray-300 rounded"
                      />
                    </div>
                    <div className="my-2 mx-1">
                      <label
                        htmlFor="type"
                        className="block text-left font-medium text-gray-700"
                      >
                        Type
                      </label>
                      <select
                        id="type"
                        name="type"
                        value={assessmentType?.type}
                        onChange={handleInputChange}
                        className="mt-1 p-2 block w-full border rounded-md"
                      >
                        <option value="scoring">Scoring</option>
                        <option value="summary">Summary</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* Categories */}
              <div className="px-4 mb-2 bg-white shadow-md rounded-lg w-full">
                <div className="pt-2 pb-6">
                  <div className="text-left flex flex-row justify-between">
                    <div className="flex flex-col">
                      <p className="text-4xl font-bold">
                        {getTerm(
                          "Categories",
                          assessmentType?.terminologies as ITerminology[]
                        )}
                      </p>
                      <p className="text-base">
                        Create, update, and organize your assessment's{" "}
                        {getTerm(
                          "categories",
                          assessmentType?.terminologies as ITerminology[]
                        )}{" "}
                        in a way that makes sense to you.
                      </p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <CategoriesForm
                      categories={assessmentType?.categories as ICategory[]}
                      terminologies={
                        assessmentType?.terminologies as ITerminology[]
                      }
                      assessmentTypeId={
                        assessmentType?.assessment_type_id as string
                      }
                      onCategoriesChange={handleCategoriesChange}
                    ></CategoriesForm>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Advanced */}
          <div key="2" className={`${activeTab === 2 ? "block" : "hidden"}`}>
            <div className="p-2 flex flex-col justify-center items-start">
              {/* Criteria Card */}
              {assessmentType?.type === "scoring" ? (
                <div className="px-4 mb-2 bg-white shadow-md rounded-lg w-full">
                  <div className="pt-2 pb-6">
                    <div className="text-left flex flex-row justify-between">
                      <div className="flex">
                        <p className="text-4xl font-bold">Criteria</p>
                      </div>
                    </div>
                    <div className="pt-2">
                      <CriteriaForm
                        criteria={assessmentType?.criteria as ICriterion[]}
                        assessmentTypeId={
                          assessmentType?.assessment_type_id as string
                        }
                        onCriteriaChange={handleCriteriaChange}
                      ></CriteriaForm>
                    </div>
                  </div>
                </div>
              ) : null}

              {/* Terminologies card */}
              <div className="px-4 mb-2 bg-white shadow-md rounded-lg w-full">
                <div className="pt-2 pb-6">
                  <div className="text-left flex flex-row justify-between">
                    <div className="flex flex-col">
                      <p className="text-4xl font-bold">Terminologies</p>
                      <p className="text-base">
                        Improve your user's experience by customizing your
                        assessment's terminologies.
                      </p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <TerminologiesForm
                      terminologies={assessmentType?.terminologies ?? null}
                      assessmentTypeId={
                        assessmentType?.assessment_type_id as string
                      }
                      onTerminologyChange={handleTerminologiesChange}
                    ></TerminologiesForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        message={modalMessage}
      />
    </div>
  );
};

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  message,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
        <p>{message}</p>
        <div className="flex justify-center space-x-4 mt-4">
          <button
            className="px-4 py-2 bg-cloc-orange text-white rounded-full py-2 px-4 mr-3 disabled:bg-cloc-orange-alt disabled:cursor-not-allowed disabled:text-gray-200"
            onClick={onClose}
          >
            <p className="flex justify-center text-bold text-base">Okay</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssessmentTypeForm;
