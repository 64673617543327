import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  IAssessment,
  ICategory,
  IQuestion,
  IResponse,
} from "../../../interfaces/assessment.interface";
import {
  clearCustomAssessment,
  saveResponses,
  setCategory,
  setCustomAssessment,
  updateResponse,
} from "../../../store/assessments/assessment.slice";
import { IoChevronBack } from "react-icons/io5";
import { IoChevronForward } from "react-icons/io5";
import {
  getNextCategory,
  getPrevCategory,
} from "../../../util/categoryPagination.util";
import { updateCustomAssessmentCategories } from "../../../util/assessmentParser.util";
import Header from "../../templates/header.component";
import Breadcrumb from "../../templates/breadcrumb.component";
import { getContrastingColor } from "../../../util/color.util";
import { setBreadcrumbs } from "../../../store/app/breadcrumbs.slice";

const Question: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const assessment: IAssessment = useSelector(
    (state: RootState) => state.assessment.customAssessment as IAssessment
  );
  const fullAssessment: IAssessment = useSelector(
    (state: RootState) => state.assessment.assessment as IAssessment
  );
  const [responses, setResponses] = useState<{ [questionId: string]: string }>(
    {}
  );
  const category: ICategory = useSelector(
    (state: RootState) => state.assessment.category as ICategory
  );
  const categories = assessment?.assessment_type?.categories || [];
  const categoryIndex = categories.findIndex(
    (cat) => cat.category_id === category?.category_id
  );
  const questions: IQuestion[] = useSelector((state: RootState) =>
    category
      ? state.assessment?.assessment?.assessment_type?.categories?.find(
          (cat) => cat.category_id === category.category_id
        )?.questions ?? []
      : []
  );
  const [requiredQuestions, setRequiredQuestions] = useState<string[]>([]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { name: "Home", path: "/" },
        {
          name: assessment.assessment_type!.name,
          path: "/assessment/category",
        },
        { name: "Categories", path: "/assessment/category/take" },
      ])
    );
  }, [dispatch]);

  // useEffect(() => {
  //   return () => {
  //     console.log("UNMOUNTING", assessment);
  //     dispatch(clearCustomAssessment());
  //   };
  // }, [dispatch]);

  useEffect(() => {
    if (!assessment || Object.keys(assessment).length === 0) {
      console.log("UNMOUNTING", assessment);
      navigate("/assessment/category");
    }
  }, [dispatch]);

  useEffect(() => {
    const initialResponses: { [key: string]: string } = {};
    const requiredQuestionIds: string[] = [];
    questions.forEach((question) => {
      const response = question.response;
      if (response) {
        // For 'multiple' type, we use option_id; for 'free' and 'number', we use answer.
        initialResponses[question.question_id] =
          response.option_id || response.answer || "";
      }

      if (question.required) {
        requiredQuestionIds.push(question.question_id);
      }
    });
    setResponses(initialResponses);
    setRequiredQuestions(requiredQuestionIds);
  }, [questions]);

  const areAllRequiredAnswered = () => {
    console.log("category", assessment.assessment_type?.name);
    console.log("REQUIRED QUESTIONS", requiredQuestions);
    console.log("Responses", responses);
    console.log(
      "result",
      requiredQuestions.every((questionId) => responses[questionId] !== "")
    );
    return requiredQuestions.every(
      (questionId) => responses[questionId] !== ""
    );
  };

  const save = (goBack: boolean, cat?: ICategory, end?: boolean) => {
    if (!assessment || !category) return;

    if (!areAllRequiredAnswered) {
      alert("Please answer all required questions.");
      return;
    }

    const responsesToSave: IResponse[] = Object.entries(responses)
      .map(([questionId, responseValue]) => {
        const question = questions.find((q) => q.question_id === questionId);
        const isOption = question?.type === "multiple";
        return {
          response_id: question?.response.response_id as string, // use existing response_id if available
          assessment_id: assessment.assessment_id as string,
          category_id: category.category_id,
          question_id: questionId,
          option_id: isOption ? responseValue : undefined,
          answer: !isOption ? responseValue : undefined,
        };
      })
      .filter((r) => r.option_id ?? r.answer); // Filter to ensure we only send responses with values

    if (responsesToSave.length) {
      dispatch(
        saveResponses({
          assessment_id: assessment.assessment_id as string,
          responses: responsesToSave,
        })
      ).then(() => {
        //update custom assessment
        dispatch(
          setCustomAssessment(
            updateCustomAssessmentCategories(assessment, fullAssessment)
          )
        );

        if (goBack) {
          dispatch(setCategory(null));
          navigate("/assessment/category");
        } else {
          if (end) {
            if (assessment.assessment_type?.type === "scoring")
              navigate("/assessment/summary");
            else navigate("/assessment/summary/action-and-task");
          }
          dispatch(setCategory(cat));
        }
      });
    } else {
      if (goBack) {
        dispatch(setCategory(null));
        navigate("/assessment/category");
      } else {
        if (end) {
          if (assessment.assessment_type?.type === "scoring")
            navigate("/assessment/summary");
          else navigate("/assessment/summary/action-and-task");
        }
        dispatch(setCategory(cat));
      }
    }
  };

  const prevCategory = getPrevCategory(category, assessment);
  const nextCategory = getNextCategory(category, assessment);
  const isLastCategory = categoryIndex === categories.length - 1;

  useEffect(() => {
    console.log("categoryIndex", categoryIndex);
    console.log("categories", categories);
    console.log("categories.length", categories.length - 1);
    console.log("isLastCategory", isLastCategory);
  }, [isLastCategory, categoryIndex, categories]);

  const handleOptionChange = (
    categoryId: string,
    questionId: string,
    value: string,
    isOption: boolean
  ) => {
    setResponses((prev) => ({ ...prev, [questionId]: value }));
    if (isOption) {
      dispatch(
        updateResponse({ categoryId, questionId, optionId: value, answer: "" })
      );
    } else {
      dispatch(
        updateResponse({ categoryId, questionId, optionId: "", answer: value })
      );
    }
  };

  const getCategoryColor = (category: ICategory) => {
    return category.color ?? "#db5b44";
  };

  if (!assessment) {
    console.log("custom assessment is empty");
    return null;
  }

  if (!category) {
    return (
      <div className="bg-white">
        <div className="z-40 fixed w-screen">
          <Header />
        </div>
        <div className="h-screen flex flex-col items-center justify-center">
          <h1 className="text-5xl font-bold text-gray-800">
            No category selected
          </h1>
          <p className="text-xl text-gray-600 mt-2">
            Please select an assessment and category.
          </p>
          <button
            className="my-4 px-4 py-2 bg-cloc-orange text-white rounded-full hover:bg-cloc-orange-alt transition-colors"
            onClick={() => navigate("/")}
          >
            Go Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <Header />
      <div>
        <div className="px-4">
          <Breadcrumb />
        </div>
        <div className="px-4 pb-2 text-left flex flex-row justify-between">
          {/* <button className="px-4 py-2 mr-3" onClick={() => save(true)}>
          <IoChevronBack className="h-6 w-6" />
        </button> */}
          <p className="text-3xl font-bold">{category.name}</p>
        </div>
      </div>

      <div className="mx-4 p-8 border-t border-cloc-blue">
        <div className="grid grid-cols-1 gap-2">
          {questions.map((question, index) => (
            <div
              key={`${question.text}-${index}`}
              className="bg-white rounded-lg shadow-md p-5"
              style={{
                backgroundColor: getCategoryColor(category) ?? "white",
                color:
                  getContrastingColor(getCategoryColor(category)) ?? "black",
              }}
            >
              <h3 className="text-lg font-semibold text-left mb-3">
                {question.order}. {question.text}
                {question.required && (
                  <span className="text-red-500 ml-1">*</span>
                )}
              </h3>
              {question.type === "multiple" &&
                question.options?.map((option) => (
                  <div
                    key={option.option_id}
                    className="flex items-center space-x-2 mb-2"
                  >
                    <input
                      type="radio"
                      id={option.option_id}
                      name={question.question_id}
                      checked={
                        responses[question.question_id] === option.option_id
                      }
                      onChange={() =>
                        handleOptionChange(
                          category.category_id,
                          question.question_id,
                          option.option_id,
                          true
                        )
                      }
                    />
                    <label
                      className="text-sm text-left"
                      htmlFor={option.option_id}
                    >
                      {option.text}
                    </label>
                  </div>
                ))}
              {question.type === "free" && (
                <textarea
                  id={`free_${question.question_id}`}
                  className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
                  value={responses[question.question_id] || ""}
                  onChange={(e) =>
                    handleOptionChange(
                      category.category_id,
                      question.question_id,
                      e.target.value,
                      false
                    )
                  }
                  style={{
                    backgroundColor: getCategoryColor(category) ?? "white",
                    color:
                      getContrastingColor(getCategoryColor(category)) ??
                      "black",
                    borderColor:
                      getContrastingColor(getCategoryColor(category)) ??
                      "black",
                  }}
                />
              )}
              {question.type === "number" && (
                <input
                  type="number"
                  id={`number_${question.question_id}`}
                  className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
                  value={responses[question.question_id] || ""}
                  onChange={(e) =>
                    handleOptionChange(
                      category.category_id,
                      question.question_id,
                      e.target.value,
                      false
                    )
                  }
                  style={{
                    backgroundColor: getCategoryColor(category) ?? "white",
                    color:
                      getContrastingColor(getCategoryColor(category)) ??
                      "black",
                    borderColor:
                      getContrastingColor(getCategoryColor(category)) ??
                      "black",
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between mb-8 px-4">
        {prevCategory ? (
          <div className="flex">
            <button
              className="flex text-sm px-4 py-2 bg-cloc-blue text-white rounded-full hover:bg-cloc-blue-alt transition-colors disabled:bg-cloc-blue-alt disabled:cursor-not-allowed disabled:text-gray-200"
              onClick={() => save(false, prevCategory, false)}
              disabled={!areAllRequiredAnswered()}
            >
              <IoChevronBack className="my-auto mr-2 h-4 w-4" />
              Previous
            </button>
            <p className="text-xs my-auto pl-3">{prevCategory.name}</p>
          </div>
        ) : (
          // Empty div to align next button
          <div></div>
        )}
        {nextCategory ? (
          <div className="flex">
            <p className="text-xs my-auto pr-3">{nextCategory.name}</p>
            <button
              className="flex text-sm px-4 py-2 bg-cloc-blue text-white rounded-full hover:bg-cloc-blue-alt transition-colors disabled:bg-cloc-blue-alt disabled:cursor-not-allowed disabled:text-gray-200"
              onClick={() => save(false, nextCategory, false)}
              disabled={!areAllRequiredAnswered()}
            >
              Next
              <IoChevronForward className="my-auto ml-2 h-4 w-4" />
            </button>
          </div>
        ) : (
          <div className="flex">
            <p className="text-xs my-auto pr-3">
              {assessment.assessment_type?.type === "scoring"
                ? "Assessment Summary"
                : "Actions and Task"}
            </p>
            <button
              className="flex text-sm px-4 py-2 bg-cloc-blue text-white rounded-full hover:bg-cloc-blue-alt transition-colors disabled:bg-cloc-blue-alt disabled:cursor-not-allowed disabled:text-gray-200"
              onClick={() => save(false, category, true)}
              disabled={!areAllRequiredAnswered()}
            >
              Next
              <IoChevronForward className="my-auto ml-2 h-4 w-4" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Question;
