// Company.tsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  archiveCompany,
  listCompany,
  setCompany,
} from "../../store/company/company.slice";
import { ICompany } from "../../interfaces/company.interface";
import { useNavigate } from "react-router-dom";
import Header from "../templates/header.component";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
        <p>{message}</p>
        <div className="flex justify-end space-x-4 mt-4">
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
            onClick={onConfirm}
          >
            Archive
          </button>
        </div>
      </div>
    </div>
  );
};

const Companies: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyToArchive, setCompanyToArchive] = useState<ICompany | null>(
    null
  );

  const companies = useSelector(
    (state: RootState) => state.company.companyList
  );

  useEffect(() => {
    dispatch(listCompany());
  }, [dispatch]);

  const handleArchiveClick = (company: ICompany) => {
    setCompanyToArchive(company);
    setIsModalOpen(true);
  };

  const handleConfirmArchive = () => {
    if (companyToArchive) {
      dispatch(archiveCompany(companyToArchive)).then(() =>
        dispatch(listCompany())
      );

      setIsModalOpen(false);
      setCompanyToArchive(null);
    }
  };

  const goHome = () => {
    navigate("/");
  };

  const goToForm = (company?: ICompany) => {
    if (company) dispatch(setCompany(company));
    navigate("/company/form");
  };

  return (
    <div className="p-4">
      <Header />
      <button
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={() => goHome()}
      >
        Go Home
      </button>
      <button
        className="px-4 py-2 bg-green-500 text-white rounded mb-4"
        onClick={() => goToForm()}
      >
        Add Company
      </button>
      <table className="w-full text-left">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {companies.map((company) => (
            <tr key={company.company_id}>
              <td>{company.name}</td>
              <td>{company.description}</td>
              <td>
                <button
                  className="px-4 py-2 bg-yellow-500 text-white rounded mr-2"
                  onClick={() => goToForm(company)}
                >
                  Edit
                </button>
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded"
                  onClick={() => handleArchiveClick(company)}
                >
                  Archive
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmArchive}
        message="Are you sure you want to archive this company?"
      />
    </div>
  );
};

export default Companies;
