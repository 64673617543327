import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-5xl font-bold text-gray-800">404</h1>
      <p className="text-xl text-gray-600 mt-2">Page Not Found</p>
      <p className="mt-4">
        <Link to="/" className="text-blue-500 hover:text-blue-700">
          Go Home
        </Link>
      </p>
    </div>
  );
};

export default NotFound;
