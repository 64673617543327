import { IAssessment, ICategory } from "../interfaces/assessment.interface";

export const getNextCategory = (
  current: ICategory,
  assessment: IAssessment
): ICategory | null => {
  if (!current) return null;
  if (!assessment) return null;
  const categories = assessment.assessment_type?.categories ?? [];
  const index = categories.findIndex(
    (category) => current.category_id === category.category_id
  );

  if (index === -1) {
    return null;
  }

  const next = index < categories.length - 1 ? categories[index + 1] : null;
  return next;
};

export const getPrevCategory = (
  current: ICategory,
  assessment: IAssessment
): ICategory | null => {
  if (!current) return null;
  if (!assessment) return null;
  const categories = assessment.assessment_type?.categories ?? [];
  const index = categories.findIndex(
    (category) => current.category_id === category.category_id
  );

  if (index === -1) {
    return null;
  }

  const prev = index > 0 ? categories[index - 1] : null;
  return prev;
};
