import React from "react";
import { IoAlertCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const ErrorComponent: React.FC<{ message?: string }> = ({
  message = "Oops! Something went wrong.",
}) => {
  const navigate = useNavigate();

  const handleRetry = () => {
    // Handle retry logic here if needed
    navigate(-1); // Go back one step in history
  };

  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex items-center flex-col">
        <IoAlertCircle className="text-red-500 h-12 w-12 mb-2" />
        <p className="text-lg font-semibold mb-2">{message}</p>
        <button
          className="px-4 py-2 bg-cloc-orange text-white rounded-full mt-2"
          onClick={handleRetry}
        >
          Retry
        </button>
      </div>
    </div>
  );
};

export default ErrorComponent;
