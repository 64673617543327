import React, { useState } from "react";

interface ColorPickerProps {
  initialColor?: string;
  onColorChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  initialColor = "#db5b44",
  onColorChange,
}) => {
  const [color, setColor] = useState(initialColor);

  const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value;
    setColor(newColor);
    onColorChange(newColor);
  };

  return (
    <div className="flex flex-col sm:flex-row items-center">
      <input
        type="color"
        value={color}
        onChange={handleColorChange}
        className="mr-0 sm:mr-2 mb-2 sm:mb-0"
      />
      <input
        type="text"
        value={color}
        readOnly
        placeholder="#000000"
        className="border-b-2 rounded-none p-1"
        style={{ borderColor: color }}
      />
    </div>
  );
};

export default ColorPicker;
