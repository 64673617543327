import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ICriterion } from "../../../interfaces/assessment.interface";
import { FaPlus } from "react-icons/fa";
import { LiaBrailleSolid } from "react-icons/lia";
import { MdDelete } from "react-icons/md";

interface CriteriaFormProps {
  criteria: ICriterion[];
  assessmentTypeId: string;
  onCriteriaChange: (criteria: ICriterion[]) => void;
}

const CriteriaForm: React.FC<CriteriaFormProps> = ({
  criteria,
  assessmentTypeId,
  onCriteriaChange,
}) => {
  const [localCriteria, setLocalCriteria] = useState<ICriterion[]>(
    criteria ?? []
  );

  useEffect(() => {
    onCriteriaChange(localCriteria);
  }, [localCriteria, onCriteriaChange]);

  const handleAddCriterion = () => {
    const newCriterion: ICriterion = {
      criterion_id: "",
      assessment_type_id: assessmentTypeId,
      name: "",
      condition: "",
      score: "",
      order: localCriteria.length + 1,
    };
    const updatedCriteria = [...localCriteria, newCriterion];

    // Update the order attribute for all criteria
    const reorderedCriteria = updatedCriteria.map((criterion, index) => ({
      ...criterion,
      order: index + 1,
    }));

    setLocalCriteria(reorderedCriteria);
  };

  const handleRemoveCriterion = (index: number) => {
    const updatedCriteria = localCriteria.filter((_, i) => i !== index);
    setLocalCriteria(updatedCriteria);
  };

  const handleChange = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const updatedCriteria = localCriteria.map((c, i) => {
      if (i === index) {
        return { ...c, [field]: value };
      }
      return c;
    });
    setLocalCriteria(updatedCriteria);
  };

  const moveCriterion = (dragIndex: number, hoverIndex: number) => {
    const updatedCriteria = [...localCriteria];
    const dragCriterion = updatedCriteria[dragIndex];
    updatedCriteria.splice(dragIndex, 1);
    updatedCriteria.splice(hoverIndex, 0, dragCriterion);

    // Update the order attribute
    const reorderedCriteria = updatedCriteria.map((criterion, index) => ({
      ...criterion,
      order: index + 1,
    }));

    setLocalCriteria(reorderedCriteria);
  };

  return (
    <div className="my-2 ">
      <DndProvider backend={HTML5Backend}>
        {localCriteria.length > 0 ? (
          <div className="space-y-4">
            {localCriteria.map((criterion, index) => (
              <CriterionItem
                key={index}
                index={index}
                criterion={criterion}
                onChange={handleChange}
                onRemove={handleRemoveCriterion}
                moveCriterion={moveCriterion}
              />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500">
            No criteria available.
          </div>
        )}
      </DndProvider>
      <button
        onClick={handleAddCriterion}
        className="mt-2 bg-none border-2 text-white py-2 px-4 rounded-md w-full"
      >
        <p className="flex justify-center text-cloc-blue text-bold text-sm">
          <span className="my-auto mx-1">
            <FaPlus />
          </span>
          Add Criteria
        </p>
      </button>
    </div>
  );
};

interface CriterionItemProps {
  criterion: ICriterion;
  index: number;
  onChange: (index: number, field: string, value: string | number) => void;
  onRemove: (index: number) => void;
  moveCriterion: (dragIndex: number, hoverIndex: number) => void;
}

const CriterionItem: React.FC<CriterionItemProps> = ({
  criterion,
  index,
  onChange,
  onRemove,
  moveCriterion,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "CRITERION",
    item: { type: "CRITERION", index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "CRITERION",
    hover(item: { type: string; index: number }) {
      if (item.index === index) {
        return;
      }
      moveCriterion(item.index, index);
      item.index = index;
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="mb-4 p-4 border rounded-md flex flex-row justify-between"
    >
      <div className="my-auto">
        <LiaBrailleSolid className="text-2xl text-gray-500" />
      </div>
      <div className="my-2 mx-1">
        <label className="block text-left text-sm font-medium text-gray-700">
          Name
        </label>
        <input
          type="text"
          value={criterion.name}
          onChange={(e) => onChange(index, "name", e.target.value)}
          className="mt-1 p-2 block w-full border rounded-md"
        />
      </div>
      <div className="my-2 mx-1 place-content-center">
        <p className="text-left text-base font-bold text-gray-700 my-auto">
          Calculated score
        </p>
      </div>
      <div className="my-2 mx-1">
        <label className="block text-left text-sm font-medium text-gray-700">
          Condition
        </label>
        <select
          value={criterion.condition}
          onChange={(e) => onChange(index, "condition", e.target.value)}
          className="mt-1 p-2 block w-full border rounded-md"
        >
          <option value="<">&lt;</option>
          <option value="<=">&le;</option>
          <option value=">">&gt;</option>
          <option value=">=">&ge;</option>
          <option value="==">==</option>
          <option value="!=">!=</option>
        </select>
      </div>
      <div className="my-2 mx-1">
        <label className="block text-left text-sm font-medium text-gray-700">
          Criteria Score
        </label>
        <input
          type="number"
          value={criterion.score}
          onChange={(e) => onChange(index, "score", parseFloat(e.target.value))}
          className="mt-1 p-2 block w-full border rounded-md"
        />
      </div>
      <button onClick={() => onRemove(index)} className="mt-2 py-1 px-4">
        <p className="flex justify-center text-bold text-base">
          <span className="my-auto mx-1">
            <MdDelete className="text-2xl text-gray-500" />
          </span>
        </p>
      </button>
    </div>
  );
};

export default CriteriaForm;
