// src/features/breadcrumbs/breadcrumbsSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Breadcrumb {
  name: string;
  path: string;
}

interface BreadcrumbsState {
  routes: Breadcrumb[];
}

const initialState: BreadcrumbsState = {
  routes: [],
};

const breadcrumbsSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
      state.routes = action.payload;
    },
  },
});

export const { setBreadcrumbs } = breadcrumbsSlice.actions;
export default breadcrumbsSlice.reducer;
