import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { AxiosError } from "axios";
import { ITeamSize } from "../../interfaces/company.interface";

export interface TeamSizeState {
  loading: boolean;
  error: string | null;
  teamSize: ITeamSize | null;
  teamSizeList: ITeamSize[];
}

const initialState: TeamSizeState = {
  loading: false,
  error: null,
  teamSize: null,
  teamSizeList: [],
};

export const createTeamSize = createAsyncThunk(
  "teamSize/create",
  async (data: ITeamSize, { rejectWithValue }) => {
    try {
      const response = await axios.post("/team-sizes", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateTeamSize = createAsyncThunk(
  "teamSize/update",
  async (data: ITeamSize, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "/team-sizes/" + data.team_size_id,
        data
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchTeamSize = createAsyncThunk(
  "teamSize/fetch",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/team-sizes/" + id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listTeamSize = createAsyncThunk(
  "teamSize/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/team-sizes");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveTeamSize = createAsyncThunk(
  "teamSize/archive",
  async (data: ITeamSize, { rejectWithValue }) => {
    try {
      const response = await axios.delete("/team-sizes/" + data.team_size_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const TeamSizeSlice = createSlice({
  name: "teamSize",
  initialState,
  reducers: {
    setTeamSize: (state, { payload }) => {
      state.teamSize = payload;
    },
  },
  extraReducers: (builder) => {
    //Create
    builder.addCase(createTeamSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTeamSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.teamSize = payload;
    });
    builder.addCase(createTeamSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Update
    builder.addCase(updateTeamSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTeamSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.teamSize = payload;
    });
    builder.addCase(updateTeamSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Fetch
    builder.addCase(fetchTeamSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchTeamSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.teamSize = payload;
    });
    builder.addCase(fetchTeamSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //List
    builder.addCase(listTeamSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listTeamSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.teamSizeList = payload;
    });
    builder.addCase(listTeamSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Archive
    builder.addCase(archiveTeamSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveTeamSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.teamSize = payload;
    });
    builder.addCase(archiveTeamSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setTeamSize } = TeamSizeSlice.actions;
export default TeamSizeSlice.reducer;
