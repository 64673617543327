// hooks/useSyncSession.ts
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateSession } from "../store/auth/auth.slice";

export const useSyncSession = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleStorageChange = () => {
      const session = getSession();
      const token = getToken();
      dispatch(updateSession({ token, session }));
    };

    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [dispatch]);
};

const getToken = () => localStorage.getItem("token") || null;
const getSession = () => {
  try {
    return JSON.parse(localStorage.getItem("session") as string) || null;
  } catch (e) {
    return null;
  }
};
