import React, { useState, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaPlus } from "react-icons/fa";
import { LiaBrailleSolid } from "react-icons/lia";
import { IoMdClose } from "react-icons/io";
import {
  IOption,
  ITerminology,
} from "../../../interfaces/assessment.interface";
import ColorPicker from "../../templates/colorPicker.component";
import { getTerm } from "../../../util/terminology.util";
import { getContrastingColor } from "../../../util/color.util";

interface OptionsFormProps {
  options: IOption[] | null;
  terminologies: ITerminology[] | null;
  questionId: string;
  color: string;
  onOptionsChange: (options: IOption[]) => void;
}

const OptionsForm: React.FC<OptionsFormProps> = ({
  options,
  terminologies,
  questionId,
  color,
  onOptionsChange,
}) => {
  const [localOptions, setLocalOptions] = useState<IOption[]>(options ?? []);

  useEffect(() => {
    onOptionsChange(localOptions);
  }, [localOptions, onOptionsChange]);

  const handleAddOption = () => {
    const newOption: IOption = {
      option_id: "",
      question_id: questionId,
      text: "",
      value: null,
      detail: "",
      order: localOptions.length + 1,
    };
    const updatedOptions = [...localOptions, newOption];

    // Update the order attribute for all criteria
    const reorderedOptions = updatedOptions.map((option, index) => ({
      ...option,
      order: index + 1,
    }));

    setLocalOptions(reorderedOptions);
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = localOptions.filter((_, i) => i !== index);
    setLocalOptions(updatedOptions);
  };

  const handleChange = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const updatedOptions = localOptions.map((c, i) => {
      if (i === index) {
        return { ...c, [field]: value };
      }
      return c;
    });
    setLocalOptions(updatedOptions);
  };

  const moveOption = (dragIndex: number, hoverIndex: number) => {
    const updatedOptions = [...localOptions];
    const dragOptions = updatedOptions[dragIndex];
    updatedOptions.splice(dragIndex, 1);
    updatedOptions.splice(hoverIndex, 0, dragOptions);

    // Update the order attribute for all criteria
    const reorderedOptions = updatedOptions.map((category, index) => ({
      ...category,
      order: index + 1,
    }));

    setLocalOptions(reorderedOptions);
  };

  const contrastingColor = getContrastingColor(color);

  return (
    <div className="my-2 ">
      <div>
        {/* <DndProvider backend={HTML5Backend}> */}
        {localOptions.length > 0 ? (
          <div className="space-y-4">
            {localOptions.map((option, index) => (
              <OptionsItem
                key={index}
                index={index}
                option={option}
                color={color}
                terminologies={terminologies as ITerminology[]}
                onChange={handleChange}
                onRemove={handleRemoveOption}
                moveOption={moveOption}
              />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500">
            No {getTerm("options", terminologies as ITerminology[])} available.
          </div>
        )}
        {/* </DndProvider> */}
      </div>
      <button
        onClick={handleAddOption}
        className="mt-2 bg-none border-2 text-white py-2 px-4 rounded-md w-full"
      >
        <p
          className="flex justify-center text-bold text-sm"
          style={{ color: contrastingColor }}
        >
          <span className="my-auto mx-1">
            <FaPlus />
          </span>
          Add {getTerm("Option", terminologies as ITerminology[])}
        </p>
      </button>
    </div>
  );
};

interface OptionsItemProps {
  option: IOption;
  terminologies: ITerminology[];
  index: number;
  color: string;
  onChange: (index: number, field: string, value: string | number) => void;
  onRemove: (index: number) => void;
  moveOption: (dragIndex: number, hoverIndex: number) => void;
}

const OptionsItem: React.FC<OptionsItemProps> = ({
  option,
  terminologies,
  index,
  color,
  onChange,
  onRemove,
  moveOption,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "OPTION",
    item: { type: "OPTION", index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "OPTION",
    hover(item: { type: string; index: number }) {
      if (item.index === index) {
        return;
      }
      moveOption(item.index, index);
      item.index = index;
    },
  });

  const contrastingColor = getContrastingColor(color);

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="mb-4 p-2 border-2 rounded-md flex flex-row"
      style={{ backgroundColor: color ?? "#ffffff", color: contrastingColor }}
    >
      <div className="my-auto w-10">
        <LiaBrailleSolid
          className="text-2xl"
          style={{ color: contrastingColor }}
        />
      </div>
      <div className="my-auto w-full">
        <div className="flex justify-between">
          <div className="flex my-2 mx-1 w-2/3">
            {/* Option Text */}
            <input
              type="text"
              name="text"
              id="text"
              placeholder={getTerm("Option", terminologies)}
              value={option.text ?? getTerm("Option", terminologies)}
              onChange={(e) => onChange(index, "text", e.target.value)}
              className="mt-1 pr-2 py-2 block w-full border-b-2 rounded-none"
              style={{
                borderColor: contrastingColor,
                backgroundColor: color,
                color: contrastingColor,
              }}
            />
          </div>
          {/* Option Text */}
          <div className="flex my-2 mx-1 w-52">
            <label
              htmlFor="value"
              className="block mx-2 my-auto text-left text-sm font-medium"
              style={{ color: contrastingColor }}
            >
              Value:
            </label>
            <input
              type="number"
              name="value"
              id="value"
              placeholder="Value"
              value={option.value as number}
              onChange={(e) => onChange(index, "value", e.target.value)}
              className="mt-1 pr-2 py-2 block w-full border-b-2 rounded-none"
              style={{
                borderColor: contrastingColor,
                backgroundColor: color,
                color: contrastingColor,
              }}
            />
          </div>
          {/* Remove Question */}
          <button
            onClick={() => onRemove(index)}
            className="mx-2 my-auto text-white rounded-md"
            style={{ color: contrastingColor }}
          >
            <p className="flex justify-center text-bold text-base">
              <span className="my-auto mx-1">
                <IoMdClose
                  className="text-2xl"
                  style={{ color: contrastingColor }}
                />
              </span>
            </p>
          </button>
        </div>
        {/* Option Detail */}
        <div>
          <textarea
            id="detail"
            name="detail"
            placeholder={getTerm("Detail", terminologies)}
            value={option.detail}
            onChange={(e) => onChange(index, "detail", e.target.value)}
            className="mt-1 pr-2 py-2 block w-full border-b-2 rounded-none"
            style={{
              borderColor: contrastingColor,
              backgroundColor: color,
              color: contrastingColor,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default OptionsForm;
