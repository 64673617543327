import { IAssessment, ICategory } from "../interfaces/assessment.interface";

export const updateCustomAssessmentCategories = (
  customAssessment: IAssessment,
  fullAssessment: IAssessment
): IAssessment => {
  // Clone the custom assessment to avoid mutating the original object
  const updatedAssessment: IAssessment = {
    ...customAssessment,
    assessment_type: {
      ...customAssessment.assessment_type,
      assessment_type_id: customAssessment.assessment_type
        ?.assessment_type_id as string,
      name: customAssessment.assessment_type?.name as string,
      description: customAssessment.assessment_type?.description as string,
      order: customAssessment.assessment_type?.order as number,
      categories: customAssessment.assessment_type?.categories.map(
        (customCat) => {
          // Find the corresponding category in the full assessment
          const fullCategory = fullAssessment.assessment_type?.categories.find(
            (fullCat) => fullCat.category_id === customCat.category_id
          );

          // If found, return the updated category from the full assessment
          if (fullCategory) {
            return fullCategory;
          }

          // If not found, return the original category in the custom assessment
          return customCat;
        }
      ) as ICategory[],
    },
  };

  return updatedAssessment;
};
