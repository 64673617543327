// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { ICategory } from "../../interfaces/assessment.interface";
import { AxiosError } from "axios";

export interface CategoryState {
  loading: boolean;
  error: string | null;
  category: ICategory | null;
  categoryList: ICategory[] | null;
}

const initialState: CategoryState = {
  loading: false,
  error: null,
  category: null,
  categoryList: null,
};

export const createCategory = createAsyncThunk(
  "category/create",
  async (data: ICategory, { rejectWithValue }) => {
    try {
      const response = await axios.post("/categories", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/update",
  async (data: ICategory, { rejectWithValue }) => {
    try {
      const response = await axios.put("/categories/" + data.category_id, data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveCategory = createAsyncThunk(
  "category/archive",
  async (data: ICategory, { rejectWithValue }) => {
    try {
      const response = await axios.delete("/categories/" + data.category_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listCategory = createAsyncThunk(
  "category/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/categories");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchCategory = createAsyncThunk(
  "category/fetch",
  async (data: ICategory, { rejectWithValue }) => {
    try {
      const response = await axios.get("/categories/" + data.category_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategory: (state, { payload }) => {
      state.category = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.category = payload;
    });
    builder.addCase(createCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.category = payload;
    });
    builder.addCase(updateCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(archiveCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.category = payload;
    });
    builder.addCase(archiveCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.category = payload;
    });
    builder.addCase(fetchCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(listCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listCategory.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.categoryList = payload;
    });
    builder.addCase(listCategory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setCategory } = categorySlice.actions;
export default categorySlice.reducer;
