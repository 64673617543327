import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { IUser } from "../../interfaces/auth.interface";
import { AuthState, logout } from "../../store/auth/auth.slice";
import {
  AssessmentTypeState,
  listAssessmentType,
} from "../../store/assessments/assessmentType.slice";
import {
  IAssessment,
  IAssessmentType,
} from "../../interfaces/assessment.interface";
import {
  createAssessment,
  listAssessment,
  searchAssessment,
  setAssessment,
} from "../../store/assessments/assessment.slice";
import { useNavigate } from "react-router-dom";
import Header from "../templates/header.component";

const Profile: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const defaultProfilePhoto =
    process.env.PUBLIC_URL + "/images/default-user.jpg";
  const heroArt = process.env.PUBLIC_URL + "/images/hero.svg";
  const [canTakeAssessment, setCanTakeAssessment] = useState(false);
  const user: IUser = useSelector(
    (state: RootState) => state.auth.user as IUser
  );
  const assessment: IAssessment = useSelector(
    (state: RootState) => state.assessment.assessment as IAssessment
  );

  useEffect(() => {
    if (user) dispatch(listAssessmentType());
  }, [dispatch, user]);

  const assessmentTypeList = useSelector<RootState, IAssessmentType[]>(
    (state) => state.assessmentType.assessmentTypeList as IAssessmentType[]
  );
  const featuredAssessmentType = assessmentTypeList?.[0];

  // TODO: set up maturity score in a "maturity score slice" in redux or determine where it should be stored
  // const maturityScore = useSelector((state: RootState) => state.maturityScore);

  const maturityScore: number = 0;

  // useEffect(() => {
  //   if (assessment) {
  //     navigate("/assessment/category");
  //   }
  // }, [assessment, navigate]);

  //TODO: add checker for idle users on assessment

  useEffect(() => {
    console.log("featuredAssessmentType", featuredAssessmentType);
    if (featuredAssessmentType && user) {
      dispatch(
        searchAssessment({
          assessment_type_id: featuredAssessmentType.assessment_type_id,
          company_id: user.company_id,
          year: getYear(),
          quarter: "Q" + getYearQuarter(),
          limit: 1,
        })
      ).then((action) => {
        const assessments = action.payload;
        if (assessments.length > 0) {
          const status = assessments[0].status;
          if (status === "completed" || status === "locked") {
            setCanTakeAssessment(false);
            //TODO: if locked, verify if current use isthe user answering the assessment
            //TODO: add prompt that current assessment for the quarter is completed/locked
          } else {
            dispatch(setAssessment(assessments[0]));
            setCanTakeAssessment(true);
          }
        } else {
          setCanTakeAssessment(true);
        }
      });
      //TODO: catch failed transaction;
    }
  }, [featuredAssessmentType, user, dispatch]);

  const getYearQuarter = (): number => {
    const currentMonth = new Date().getMonth();
    return Math.floor(currentMonth / 3) + 1;
  };

  const getYear = (): number => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };

  const takeAssessment = () => {
    //TODO: lock assessment also
    if (canTakeAssessment) {
      if (!assessment) {
        dispatch(
          createAssessment({
            user: user,
            assessmentType: featuredAssessmentType,
          })
        ).then(() => {
          navigate("/assessment/category");
        });
      } else {
        navigate("/assessment/category");
      }
    }
  };

  const goToAssessmentTypes = () => {
    navigate("/assessment-type");
  };

  return (
    <div className="relative min-h-screen bg-gray-100">
      <Header />

      <div className="p-4 flex flex-row justify-center items-start">
        {/* Card */}
        <div className="p-4 bg-white shadow-md rounded-lg w-full h-56">
          <div className="pl-5 pt-2 pb-6">
            <div className="px-4 text-left flex flex-row border-b-2 border-cloc-blue justify-between">
              <div className="flex">
                <p className="text-4xl font-bold">Profile</p>
              </div>
              {/* TODO: add functionality for editing profile */}
              <div className="my-auto justify-end">
                <button
                  className="px-4 py-2 bg-cloc-blue text-white rounded-full mr-3"
                  // onClick={() => goToActionAndTask()}
                >
                  Edit
                </button>
              </div>
            </div>
            <div className="text-left pt-2">
              <p className="text-xl font-base mx-auto">
                Name: {user.first_name + " " + user.last_name}
              </p>
              <p className="text-xl font-base mx-auto">E-mail: {user.email}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
