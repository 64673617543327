import { ICategory, IQuestion } from "../interfaces/assessment.interface";
import { deepEqualOptions } from "./option.util";
import { deepEqualQuestions } from "./question.util";

export const deepEqualCategories = (
  arr1: ICategory[],
  arr2: ICategory[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const categories1 = arr1[i];
    const categories2 = arr2[i];

    if (
      categories1.category_id !== categories2.category_id ||
      categories1.assessment_type_id !== categories2.assessment_type_id ||
      categories1.name !== categories2.name ||
      categories1.description !== categories2.description ||
      categories1.color !== categories2.color ||
      categories1.order !== categories2.order ||
      !deepEqualQuestions(categories1.questions, categories2.questions)
    ) {
      return false;
    }
  }

  return true;
};
