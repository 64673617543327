import { IQuestion } from "../interfaces/assessment.interface";
import { deepEqualOptions } from "./option.util";

export const deepEqualQuestions = (
  arr1: IQuestion[],
  arr2: IQuestion[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const questions1 = arr1[i];
    const questions2 = arr2[i];

    if (
      questions1.question_id !== questions2.question_id ||
      questions1.category_id !== questions2.category_id ||
      questions1.type !== questions2.type ||
      questions1.text !== questions2.text ||
      questions1.order !== questions2.order ||
      questions1.required !== questions2.required ||
      !deepEqualOptions(questions1.options, questions2.options)
    ) {
      return false;
    }
  }

  return true;
};
