// Company.tsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { IRole, IUser } from "../../../interfaces/auth.interface";
import { archiveRole, listRole, setRole } from "../../../store/auth/role.slice";
import {
  archiveAssessment,
  createAssessment,
  listAssessment,
  searchAssessment,
  setAssessment,
} from "../../../store/assessments/assessment.slice";
import {
  IAssessment,
  IAssessmentType,
} from "../../../interfaces/assessment.interface";
import {
  archiveAssessmentType,
  listAssessmentType,
  orderAssessmentTypes,
  setAssessmentType,
  setAssessmentTypeList,
} from "../../../store/assessments/assessmentType.slice";
import Header from "../../templates/header.component";
import { IoChevronBack } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LiaBrailleSolid } from "react-icons/lia";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaClipboardList } from "react-icons/fa";
import Breadcrumb from "../../templates/breadcrumb.component";
import { setBreadcrumbs } from "../../../store/app/breadcrumbs.slice";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  message: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  message,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full text-left">
        <p>{message}</p>
        <div className="flex justify-between space-x-4 mt-4">
          <button
            className="px-4 py-2 bg-cloc-blue text-white rounded-full py-2 px-4 mr-3 disabled:bg-cloc-blue-alt disabled:cursor-not-allowed disabled:text-gray-200"
            onClick={onClose}
          >
            <p className="flex justify-center text-bold text-base">Cancel</p>
          </button>

          <button
            className="px-4 py-2 bg-red-500 text-white rounded-full py-2 px-4 mr-3 disabled:bg-red-800 disabled:cursor-not-allowed disabled:text-gray-200"
            onClick={onConfirm}
          >
            <p className="flex justify-center text-bold text-base">Archive</p>
          </button>
        </div>
      </div>
    </div>
  );
};

const AssessmentType: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTakeAssessment, setIsTakeAssessment] = useState(false);
  const [assessmentTypeToArchive, setAssessmentTypeToArchive] =
    useState<IAssessmentType | null>(null);
  const user: IUser = useSelector(
    (state: RootState) => state.auth.user as IUser
  );
  const assessmentTypes = useSelector(
    (state: RootState) =>
      state.assessmentType.assessmentTypeList as IAssessmentType[]
  );
  const assessment = useSelector(
    (state: RootState) => state.assessment.assessment as IAssessment | null
  );

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { name: "Home", path: "/" },
        {
          name: "Assessment Types",
          path: "/assessment-type",
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    if (assessment && isTakeAssessment) navigate("/assessment/category");
  }, [assessment, dispatch]);

  useEffect(() => {
    dispatch(listAssessmentType());
  }, [dispatch]);

  const handleArchiveClick = (assessmentType: IAssessmentType) => {
    setAssessmentTypeToArchive(assessmentType);
    setIsModalOpen(true);
  };

  const handleConfirmArchive = () => {
    if (assessmentTypeToArchive) {
      // updates local copy of assessment type list
      const updatedAssessmentType = assessmentTypes.filter(
        (_, i) => i !== assessmentTypeToArchive.order - 1
      );
      dispatch(setAssessmentTypeList(updatedAssessmentType));

      //Update the order of the existing assessment types
      dispatch(orderAssessmentTypes(updatedAssessmentType));

      dispatch(archiveAssessmentType(assessmentTypeToArchive)).then(() =>
        dispatch(listAssessmentType())
      );

      setIsModalOpen(false);
      setAssessmentTypeToArchive(null);
    }
  };

  const goHome = () => {
    navigate("/");
  };

  const goToForm = (assessmentType?: IAssessmentType) => {
    if (assessmentType) dispatch(setAssessmentType(assessmentType));
    navigate("/assessment-type/form");
  };

  const moveAssessmentType = (dragIndex: number, hoverIndex: number) => {
    const updatedAssessmentTypes = [...assessmentTypes];
    const dragAssessmentType = updatedAssessmentTypes[dragIndex];
    updatedAssessmentTypes.splice(dragIndex, 1);
    updatedAssessmentTypes.splice(hoverIndex, 0, dragAssessmentType);

    // Update the order attribute
    const reorderedAssessmentTypes = updatedAssessmentTypes.map(
      (assessmentType, index) => ({
        ...assessmentType,
        order: index + 1,
      })
    );

    dispatch(setAssessmentTypeList(reorderedAssessmentTypes));
    dispatch(orderAssessmentTypes(reorderedAssessmentTypes));
  };

  const handleChange = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const updatedAssessmentType = assessmentTypes.map((c, i) => {
      if (i === index) {
        return { ...c, [field]: value };
      }
      return c;
    });
    dispatch(setAssessmentTypeList(updatedAssessmentType));
  };

  const getYearQuarter = (): number => {
    const currentMonth = new Date().getMonth();
    return Math.floor(currentMonth / 3) + 1;
  };

  const getYear = (): number => {
    const currentDate = new Date();
    return currentDate.getFullYear();
  };

  const take = (assessmentType: IAssessmentType) => {
    // TODO: redirect to take assessment
    dispatch(
      searchAssessment({
        assessment_type_id: assessmentType.assessment_type_id,
        company_id: user.company_id,
        year: getYear(),
        quarter: "Q" + getYearQuarter(),
        limit: 1,
      })
    ).then((action) => {
      const assessments = action.payload;
      // let canTakeAssessment = false;

      if (assessments.length > 0) {
        const status = assessments[0].status;
        if (status === "completed" || status === "locked") {
          setIsTakeAssessment(false);
          // canTakeAssessment = false;
          //TODO: if locked, verify if current use isthe user answering the assessment
          //TODO: add prompt that current assessment for the quarter is completed/locked
        } else {
          setIsTakeAssessment(true);
          dispatch(setAssessment(assessments[0]));
        }
      } else {
        setIsTakeAssessment(true);
        dispatch(
          createAssessment({
            user: user,
            assessmentType: assessmentType,
          })
        );
      }
    });
  };

  return (
    <div className="">
      <Header />
      <div>
        <div className="px-4">
          <Breadcrumb />
        </div>
        <div className="px-4 pb-2 text-left flex flex-row justify-between">
          <div className="">
            <p className="text-3xl font-bold">Other Assessment Types</p>
          </div>
          <div className="my-auto flex justify-end">
            <button
              className="px-4 py-2 bg-cloc-orange text-white rounded-full py-2 px-4 mr-3 disabled:bg-cloc-orange-alt disabled:cursor-not-allowed disabled:text-gray-200"
              onClick={() => goToForm()}
            >
              <p className="flex justify-center text-bold text-base">
                <span className="my-auto mx-1 relative bottom-0.25">
                  <IoMdAdd />
                </span>
                New Assessment Type
              </p>
            </button>
          </div>
        </div>
      </div>

      <div className="mx-4 p-8 border-t border-cloc-blue">
        <DndProvider backend={HTML5Backend}>
          {assessmentTypes?.length > 0 ? (
            <div className="space-y-4">
              {assessmentTypes?.map((assessmentType, index) => (
                <AssessmentTypeItem
                  key={index}
                  index={index}
                  assessmentType={assessmentType}
                  onChange={handleChange}
                  moveAssessmentType={moveAssessmentType}
                  goToForm={goToForm}
                  handleArchiveClick={handleArchiveClick}
                  take={take}
                />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-500">
              No Assessments available.
            </div>
          )}
        </DndProvider>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmArchive}
        message="Are you sure you want to archive this assessment type?"
      />
    </div>
  );
};

interface AssessmentTypeItemProps {
  assessmentType: IAssessmentType;
  index: number;
  onChange: (index: number, field: string, value: string | number) => void;
  moveAssessmentType: (dragIndex: number, hoverIndex: number) => void;
  goToForm: (assessmentType: IAssessmentType) => void;
  handleArchiveClick: (assessmentType: IAssessmentType) => void;
  take: (assessmentType: IAssessmentType) => void;
}

const AssessmentTypeItem: React.FC<AssessmentTypeItemProps> = ({
  assessmentType,
  index,
  onChange,
  moveAssessmentType,
  goToForm,
  handleArchiveClick,
  take,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "ASSESSMENTTYPE",
    item: { type: "ASSESSMENTTYPE", index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "ASSESSMENTTYPE",
    hover(item: { type: string; index: number }) {
      if (item.index === index) {
        return;
      }
      moveAssessmentType(item.index, index);
      item.index = index;
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="mb-4 p-4 border rounded-md flex flex-row"
    >
      <div className="my-auto w-10">
        <LiaBrailleSolid className="text-2xl text-gray-500" />
      </div>
      <div className="my-auto w-full flex justify-between">
        <div className="my-auto text-left">
          <h3 className="text-lg font-semibold">{assessmentType.name}</h3>
          <p>{assessmentType.description}</p>
        </div>
        <div>
          <button
            className="px-4 py-2 bg-cloc-blue text-white rounded-full py-2 px-4 mr-3 disabled:bg-cloc-blue-alt disabled:cursor-not-allowed disabled:text-gray-200"
            onClick={() => take(assessmentType)}
          >
            <p className="flex justify-center text-bold text-base">
              <span className="my-auto mr-1 relative bottom-0.25">
                <FaClipboardList />
              </span>
              Take
            </p>
          </button>
          <button
            className="px-4 py-2 bg-cloc-orange text-white rounded-full py-2 px-4 mr-3 disabled:bg-cloc-orange-alt disabled:cursor-not-allowed disabled:text-gray-200"
            onClick={() => goToForm(assessmentType)}
          >
            <p className="flex justify-center text-bold text-base">
              <span className="my-auto mr-1 relative bottom-0.25">
                <MdEdit />
              </span>
              Edit
            </p>
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-full py-2 px-4 mr-3 disabled:bg-red-800 disabled:cursor-not-allowed disabled:text-gray-200"
            onClick={() => handleArchiveClick(assessmentType)}
          >
            <p className="flex justify-center text-bold text-base">
              <span className="my-auto mr-1 relative bottom-0.25">
                <MdDelete />
              </span>
              Archive
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssessmentType;
