import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { ICriterion } from "../../interfaces/assessment.interface";
import { evaluateScore } from "../../util/criteriaEvaluator.util";

interface ProgressBarProps {
  value: number;
  criteria: ICriterion[];
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, criteria }) => {
  const max = Math.max(
    ...criteria.map((criterion: ICriterion) => parseFloat(criterion.score))
  );
  const percentage = (value / max) * 100;
  const criterion = evaluateScore(value, criteria);

  console.log("value", value);
  console.log("max", max);
  console.log("percentage", percentage);

  return (
    <div className="relative w-full bg-gray-200 rounded-full h-8">
      <div className="relative w-full">
        <div className="absolute top-0 left-0 w-full flex justify-between text-xs">
          <div
            className="absolute text-base font-semibold"
            style={{
              left: `${percentage - 3}%`,
              top: "4px",
              transform: "translateX(-50%)",
            }}
          >
            <span className={`text-white`}>{percentage.toFixed(0)}%</span>
          </div>
        </div>
        <div
          className={`bg-${getColor(percentage)} rounded-full h-8`}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className="text-left">
        <p className="text-sm font-medium">
          {value.toFixed(2) + " - "}
          <span className={`text-${getColor(percentage)} font-bold`}>
            {criterion?.name}
          </span>
        </p>
      </div>
    </div>
  );
};

const getColor = (percentage: number): string => {
  if (percentage <= 25) return "cloc-orange-alt";
  else if (percentage <= 50) return "cloc-orange";
  else if (percentage <= 75) return "cloc-blue-alt";
  else if (percentage <= 100) return "cloc-blue";
  else return "cloc-blue";
};

export default ProgressBar;
