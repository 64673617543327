import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { AxiosError } from "axios";
import { IOrganizationSize } from "../../interfaces/company.interface";

export interface OrganizationSizeState {
  loading: boolean;
  error: string | null;
  organizationSize: IOrganizationSize | null;
  organizationSizeList: IOrganizationSize[];
}

const initialState: OrganizationSizeState = {
  loading: false,
  error: null,
  organizationSize: null,
  organizationSizeList: [],
};

export const createOrganizationSize = createAsyncThunk(
  "organizationSize/create",
  async (data: IOrganizationSize, { rejectWithValue }) => {
    try {
      const response = await axios.post("/organization-sizes", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateOrganizationSize = createAsyncThunk(
  "organizationSize/update",
  async (data: IOrganizationSize, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "/organization-sizes/" + data.organization_size_id,
        data
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchOrganizationSize = createAsyncThunk(
  "organizationSize/fetch",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/organization-sizes/" + id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listOrganizationSize = createAsyncThunk(
  "organizationSize/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/organization-sizes");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveOrganizationSize = createAsyncThunk(
  "organizationSize/archive",
  async (data: IOrganizationSize, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        "/organization-sizes/" + data.organization_size_id
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const OrganizationSizeSlice = createSlice({
  name: "organizationSize",
  initialState,
  reducers: {
    setOrganizationSize: (state, { payload }) => {
      state.organizationSize = payload;
    },
  },
  extraReducers: (builder) => {
    //Create
    builder.addCase(createOrganizationSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrganizationSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.organizationSize = payload;
    });
    builder.addCase(createOrganizationSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Update
    builder.addCase(updateOrganizationSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateOrganizationSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.organizationSize = payload;
    });
    builder.addCase(updateOrganizationSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Fetch
    builder.addCase(fetchOrganizationSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchOrganizationSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.organizationSize = payload;
    });
    builder.addCase(fetchOrganizationSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //List
    builder.addCase(listOrganizationSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listOrganizationSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.organizationSizeList = payload;
    });
    builder.addCase(listOrganizationSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Archive
    builder.addCase(archiveOrganizationSize.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveOrganizationSize.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.organizationSize = payload;
    });
    builder.addCase(archiveOrganizationSize.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setOrganizationSize } = OrganizationSizeSlice.actions;
export default OrganizationSizeSlice.reducer;
