// src/components/Breadcrumb.tsx
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/hi2";

const Breadcrumb: React.FC = () => {
  const routes = useSelector((state: RootState) => state.breadcrumbs.routes);
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <nav>
      <ul className="flex space-x-1">
        {routes.map((route, index) => (
          <li
            key={`route-${index}`}
            className="flex items-center text-xs md:text-sm"
          >
            {index < routes.length - 1 ? (
              <button
                onClick={() => handleNavigation(route.path)}
                className="text-cloc-blue "
              >
                {route.name}
              </button>
            ) : (
              <span className="text-gray-500">{route.name}</span>
            )}
            {index < routes.length - 1 && (
              <span className="mx-1">
                <HiOutlineChevronRight />
              </span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
