// src/features/auth/authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { IQuestion } from "../../interfaces/assessment.interface";
import { AxiosError } from "axios";

export interface QuestionState {
  loading: boolean;
  error: string | null;
  question: IQuestion | null;
  questionList: IQuestion[] | null;
}

const initialState: QuestionState = {
  loading: false,
  error: null,
  question: null,
  questionList: null,
};

export const createQuestion = createAsyncThunk(
  "question/create",
  async (data: IQuestion, { rejectWithValue }) => {
    try {
      const response = await axios.post("/questions", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateQuestion = createAsyncThunk(
  "question/update",
  async (data: IQuestion, { rejectWithValue }) => {
    try {
      const response = await axios.put("/questions/" + data.question_id, data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveQuestion = createAsyncThunk(
  "question/archive",
  async (data: IQuestion, { rejectWithValue }) => {
    try {
      const response = await axios.delete("/questions/" + data.question_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listQuestion = createAsyncThunk(
  "question/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/questions");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchQuestion = createAsyncThunk(
  "question/fetch",
  async (data: IQuestion, { rejectWithValue }) => {
    try {
      const response = await axios.get("/questions/" + data.question_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    setQuestion: (state, { payload }) => {
      state.question = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createQuestion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.question = payload;
    });
    builder.addCase(createQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateQuestion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.question = payload;
    });
    builder.addCase(updateQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(archiveQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveQuestion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.question = payload;
    });
    builder.addCase(archiveQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchQuestion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.question = payload;
    });
    builder.addCase(fetchQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(listQuestion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listQuestion.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.questionList = payload;
    });
    builder.addCase(listQuestion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setQuestion } = questionSlice.actions;
export default questionSlice.reducer;
