import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import {
  IAssessment,
  ICategory,
  ITerminology,
  IWeight,
} from "../../../interfaces/assessment.interface";
import {
  clearAssessment,
  clearCustomAssessment,
  saveWeights,
  setCategory,
  setCustomAssessment,
} from "../../../store/assessments/assessment.slice";
import { IoChevronBack } from "react-icons/io5";
import Header from "../../templates/header.component";
import { getTerm } from "../../../util/terminology.util";
import Breadcrumb from "../../templates/breadcrumb.component";
import { setBreadcrumbs } from "../../../store/app/breadcrumbs.slice";
import Loading from "../../templates/loading.component";
import ErrorComponent from "../../templates/error.component";
import { getContrastingColor } from "../../../util/color.util";

// TODO: indicate which categories are answered

const ChooseCategory: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const assessment: IAssessment | null = useSelector(
    (state: RootState) => state.assessment.assessment ?? null
  );
  const assessmentLoading: boolean | null = useSelector(
    (state: RootState) => state.assessment.loading ?? null
  );
  const assessmentError: string | null = useSelector(
    (state: RootState) => state.assessment.error ?? null
  );
  const customAssessment: IAssessment | null = useSelector(
    (state: RootState) => state.assessment.customAssessment ?? null
  );
  const category: ICategory = useSelector(
    (state: RootState) => state.assessment.category as ICategory
  );
  const [selectedCategories, setSelectedCategories] = useState<ICategory[]>([]);
  const [localWeights, setLocalWeights] = useState<IWeight[]>();
  const [isCustomWeight, setIsCustomWeight] = useState(false);
  const [inputValues, setInputValues] = useState<{ [key: string]: string }>({});
  const [isTakeClicked, setIsTakeClicked] = useState<boolean>(false);
  const prevWeightsRef = useRef<IWeight[]>([]);

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { name: "Home", path: "/" },
        {
          name: assessment!.assessment_type!.name,
          path: "/assessment/category",
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    console.log("MOUNTING. CLEARING CUSTOM ASSESSMENT");
    setIsTakeClicked(false);
    dispatch(clearCustomAssessment());
  }, [dispatch]);

  useEffect(() => {
    if (assessment) {
      setLocalWeights(assessment.weights ?? []);
      const initialInputValues = assessment.weights?.reduce((acc, weight) => {
        acc[weight.category_id] = weight.value.toFixed(2);
        return acc;
      }, {} as { [key: string]: string });
      setInputValues(initialInputValues ?? {});

      prevWeightsRef.current = assessment.weights ?? [];
    }
  }, [navigate, assessment]);

  useEffect(() => {
    //set first category
    if (customAssessment?.assessment_type) {
      dispatch(setCategory(customAssessment.assessment_type?.categories[0]));
    }
    //TODO: fix this. Looping...
    // after mounting. The clear custom assessment is not yet finished, causing this to loop
    if (customAssessment !== null && isTakeClicked) {
      console.log("NAVIGATE TO QUESTIONS", customAssessment);
      navigate("/assessment/category/take");
    }
    if (customAssessment !== null)
      console.log("NAVIGATE TO QUESTIONS", customAssessment);
    else console.log("No customAssessment", customAssessment);
  }, [customAssessment, dispatch, navigate]);

  const takeAssessment = () => {
    console.log("selectedCategories", selectedCategories);
    setIsTakeClicked(true);

    // Update localWeights with current inputValues
    const updatedWeights = localWeights?.map((weight) => ({
      weight_id: weight.weight_id,
      category_id: weight.category_id,
      assessment_id: weight.assessment_id,
      value: isCustomWeight ? parseFloat(inputValues[weight.category_id]) : 1.0,
    })) as IWeight[];

    // Compare localWeights with prevWeightsRef.current
    const weightsChanged = !compareWeights(
      prevWeightsRef.current,
      updatedWeights
    );

    if (weightsChanged || isCustomWeight === false) {
      console.log("Local weights have changed. Dispatch can be edited.");
      // Uncomment the dispatch line below when ready to dispatch
      dispatch(
        saveWeights({
          assessment_id: assessment!.assessment_id as string,
          weights: updatedWeights,
        })
      );
    }

    //set custom assessment
    dispatch(
      setCustomAssessment({
        ...assessment!,
        assessment_type: {
          ...assessment!.assessment_type,
          categories: selectedCategories,
        },
        weights: updatedWeights,
      })
    );
  };

  const toggleCategory = (category: ICategory) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((cat) => cat !== category)
        : [...prev, category]
    );
    console.log("selectedCategory", selectedCategories);
  };

  const selectAllCategories = (isChecked: boolean) => {
    if (isChecked) {
      const allCategories = assessment?.assessment_type?.categories.map(
        (cat) => cat
      );
      setSelectedCategories(allCategories ?? []);
    } else {
      setSelectedCategories([]);
    }
  };

  const validateAndFormat = (value: string): string => {
    // Remove any non-digit characters and limit to 2 decimal places
    const trimmedValue = value.replace(/[^\d.]/g, "");
    const parsedValue = parseFloat(trimmedValue);

    if (isNaN(parsedValue) || parsedValue < 1.0 || parsedValue > 3.0) {
      return "1.00";
    }

    // Round to 2 decimal places
    return parsedValue.toFixed(2);
  };

  const handleInputChange = (categoryId: string, value: string) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [categoryId]: value,
    }));
  };

  const handleBlur = (category: ICategory, value: string) => {
    const formattedValue = validateAndFormat(value);

    setLocalWeights((prevWeights) =>
      prevWeights?.map((weight) =>
        weight.category_id === category.category_id
          ? { ...weight, value: parseFloat(formattedValue) }
          : weight
      )
    );

    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [category.category_id]: formattedValue,
    }));
  };

  const compareWeights = (
    weights1: IWeight[],
    weights2: IWeight[]
  ): boolean => {
    if (weights1.length !== weights2.length) {
      return false;
    }

    for (let i = 0; i < weights1.length; i++) {
      if (
        weights1[i].category_id !== weights2[i].category_id ||
        weights1[i].value !== weights2[i].value
      ) {
        return false;
      }
    }

    return true;
  };

  const getCategoryColor = (category: ICategory) => {
    return category.color ?? "#db5b44";
  };

  if (!assessment) {
    return (
      <div className="bg-white">
        <div className="z-40 fixed w-screen">
          <Header />
        </div>
        <div className="h-screen flex flex-col items-center justify-center">
          <h1 className="text-5xl font-bold text-gray-800">
            No assessment set
          </h1>
          <p className="text-xl text-gray-600 mt-2">
            Please select an assessment type to take.
          </p>
          <button
            className="my-4 px-4 py-2 bg-cloc-orange text-white rounded-full hover:bg-cloc-orange-alt transition-colors"
            onClick={() => navigate("/")}
          >
            Go Home
          </button>
        </div>
      </div>
    );
  }

  // if (assessmentLoading) {
  //   return <Loading />;
  //   //TODO: Produces loop in component. Find a way to fix this while showing loading
  // }

  if (assessmentError !== null) {
    return <ErrorComponent message={assessmentError} />;
  }

  return (
    <div className="">
      <Header />
      <div>
        <div className="px-4">
          <Breadcrumb />
        </div>
        <div className="px-4 pb-2 text-left lg:flex lg:flex-row justify-between">
          {/* <div className="flex"> */}
          <div className="">
            {/* <button className="px-4 py-2 mr-3" onClick={() => goHome()}>
            <IoChevronBack className="h-6 w-6" />
          </button> */}
            <p className="text-xl md:text-3xl font-bold">
              Select a{" "}
              {getTerm(
                "category",
                assessment!.assessment_type?.terminologies as ITerminology[]
              )}
            </p>
          </div>
          <div className="my-1 lg:my-auto flex justify-between lg:justify-end text-xs md:text-sm lg:text-base">
            {assessment!.assessment_type?.type === "scoring" ? (
              <div className="md:px-4 flex justify-start items-center my-auto">
                <input
                  name="customWeights"
                  id="customWeights"
                  type="checkbox"
                  checked={isCustomWeight}
                  onChange={(e) => setIsCustomWeight(e.target.checked)}
                />
                <label className="ml-1 md:ml-2" htmlFor="customWeights">
                  Customize Weights
                </label>
              </div>
            ) : null}
            <div className="md:px-4 flex justify-start items-center my-auto">
              <input
                name="selectAll"
                id="selectAll"
                type="checkbox"
                checked={
                  selectedCategories.length ===
                  assessment?.assessment_type?.categories.length
                }
                onChange={(e) => selectAllCategories(e.target.checked)}
              />
              <label className="ml-1 md:ml-2" htmlFor="selectAll">
                Select All
              </label>
            </div>
            <button
              className="px-4 py-2 bg-cloc-orange text-white rounded-full mr-3 disabled:bg-cloc-orange-alt disabled:cursor-not-allowed disabled:text-gray-200"
              onClick={() => takeAssessment()}
              disabled={selectedCategories.length <= 0}
            >
              <p className="my-0">Take</p>
            </button>
          </div>
        </div>
      </div>

      {/* Cards */}
      <div className="mx-4 p-2 md:p-8 border-t border-cloc-blue">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {/* TODO: fix style */}
          {assessment?.assessment_type?.categories?.map((category) => (
            <div
              className={`flex justify-between rounded-lg shadow-md px-4 xl:px-10 ${
                isCustomWeight ? "py-6 md:py-8" : "py-8 md:py-10"
              } ${
                selectedCategories.includes(category)
                  ? "bg-cloc-orange text-white"
                  : "bg-white text-black"
              }`}
              style={{
                backgroundColor: selectedCategories.includes(category)
                  ? getCategoryColor(category)
                  : "white",
                color: selectedCategories.includes(category)
                  ? getContrastingColor(getCategoryColor(category))
                  : "black",
              }}
              key={category.category_id}
              // onClick={() => toggleCategory(category)}
            >
              <div className="flex justify-start">
                <input
                  name={`selectCategory-${category.category_id}`}
                  id={`selectCategory-${category.category_id}`}
                  type="checkbox"
                  checked={selectedCategories.includes(category)}
                  onChange={() => toggleCategory(category)}
                  className="mx-2 cursor-pointer"
                />
                <label
                  className="text-left text-sm md:text-lg font-semibold my-auto cursor-pointer"
                  htmlFor={`selectCategory-${category.category_id}`}
                >
                  {category.name}
                </label>
              </div>
              {isCustomWeight ? (
                <div className="w-16 my-auto">
                  <label
                    htmlFor="weight"
                    className="block mx-2 my-auto text-left text-xs md:text-sm font-medium"
                  >
                    Weight:
                  </label>
                  <input
                    type="text"
                    name={`weight-${category.category_id}`}
                    id={`weight-${category.category_id}`}
                    placeholder="Weight"
                    value={inputValues[category.category_id] || ""}
                    onChange={(e) =>
                      handleInputChange(category.category_id, e.target.value)
                    }
                    onBlur={(e) => handleBlur(category, e.target.value)}
                    // className={`block w-full border-b-2 rounded-none text-center ${
                    //   selectedCategories.includes(category)
                    //     ? "bg-cloc-orange text-white"
                    //     : "bg-white text-black"
                    // }`}
                    className={`block w-full border-b-2 rounded-none text-center text-sm md:text-base`}
                    style={{
                      backgroundColor: selectedCategories.includes(category)
                        ? getCategoryColor(category)
                        : "white",
                      color: selectedCategories.includes(category)
                        ? getContrastingColor(getCategoryColor(category))
                        : "black",
                    }}
                  />
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChooseCategory;
