import React, { useState, useEffect } from "react";
import {
  ICriterion,
  ITerminology,
} from "../../../interfaces/assessment.interface";
import { adjustCapitalization } from "../../../util/terminology.util";

interface TerminologiesFormProps {
  terminologies: ITerminology[] | null;
  assessmentTypeId: string;
  onTerminologyChange: (terminologies: ITerminology[]) => void;
}

const defaultTerminologies: ITerminology[] = [
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "category",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "categories",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "question",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "questions",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "option",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "options",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "classification",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "classifications",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "detail",
    value: "",
  },
  {
    terminology_id: "",
    assessment_type_id: "",
    term: "details",
    value: "",
  },
];

const TerminologiesForm: React.FC<TerminologiesFormProps> = ({
  terminologies,
  assessmentTypeId,
  onTerminologyChange,
}) => {
  const sortTerminologies = (terminologies: ITerminology[]) => {
    return [...terminologies].sort((a, b) => {
      if (a.term < b.term) {
        return -1;
      }
      if (a.term > b.term) {
        return 1;
      }
      return 0;
    });
  };

  const [localTerminologies, setLocalTerminologies] = useState<ITerminology[]>(
    sortTerminologies(
      terminologies ? [...terminologies] : [...defaultTerminologies]
    )
  );

  useEffect(() => {
    onTerminologyChange(localTerminologies);
  }, [localTerminologies, onTerminologyChange]);

  const handleTerminologyChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;

    // Create a new array to avoid mutating the state directly
    const updatedTerminologies = localTerminologies.map((terminology) => {
      if (terminology.term === name) {
        return { ...terminology, value: value.toLowerCase() };
      }
      return terminology;
    });

    // Check if the term was found and updated, otherwise add a new term
    const termExists = updatedTerminologies.some(
      (terminology) => terminology.term === name
    );

    if (!termExists) {
      updatedTerminologies.push({
        terminology_id: "",
        assessment_type_id: assessmentTypeId,
        term: name,
        value: value.toLowerCase(),
      });
    }

    setLocalTerminologies(sortTerminologies(updatedTerminologies));
  };

  return (
    <div className="my-2 grid grid-cols-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
      {localTerminologies.map((terminology, index) => (
        <div
          key={`${terminology.term}-${index}`}
          className="flex flex-row my-1 justify-left"
        >
          <label
            htmlFor={`${terminology.term}`}
            className="block text-left my-auto mr-2 w-1/6"
          >
            {adjustCapitalization(terminology.term, "Xxxxxxx")}
          </label>
          <input
            type="text"
            id={`${terminology.term}`}
            name={`${terminology.term}`}
            value={terminology.value}
            onChange={handleTerminologyChange}
            className="block w-full p-2 border border-gray-300 rounded w-5/6"
          />
        </div>
      ))}
    </div>
  );
};

export default TerminologiesForm;
