import { ITerminology } from "../interfaces/assessment.interface";

export const getTerm = (
  term: string,
  terminologies: ITerminology[]
): string => {
  if (!terminologies) return term;
  const lowerCaseTerm = term.toLowerCase();
  const terminology = terminologies.find(
    (t) => t.term.toLowerCase() === lowerCaseTerm
  );

  if (terminology?.value) {
    return adjustCapitalization(terminology.value, term);
  }

  return term;
};

// Helper function to adjust the capitalization of the found value to match the original term
export const adjustCapitalization = (
  value: string,
  template: string
): string => {
  if (template === template.toUpperCase()) {
    return value.toUpperCase();
  } else if (template === template.toLowerCase()) {
    return value.toLowerCase();
  } else if (
    template[0] === template[0].toUpperCase() &&
    template.slice(1) === template.slice(1).toLowerCase()
  ) {
    return value[0].toUpperCase() + value.slice(1).toLowerCase();
  } else if (
    template[0] === template[0].toUpperCase() &&
    template !== template.toLowerCase()
  ) {
    // PascalCase: first letter uppercase and contains at least one uppercase letter in the rest of the string
    return value[0].toUpperCase() + value.slice(1);
  } else if (
    template[0] === template[0].toLowerCase() &&
    template !== template.toUpperCase()
  ) {
    // camelCase: first letter lowercase and contains at least one uppercase letter in the rest of the string
    return value[0].toLowerCase() + value.slice(1);
  } else {
    return value; // default case for other mixed capitalization patterns
  }
};

export const deepEqualTerminologies = (
  arr1: ITerminology[],
  arr2: ITerminology[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const terminologies1 = arr1[i];
    const terminologies2 = arr2[i];

    console.log(
      "terminology_id",
      terminologies1.terminology_id !== terminologies2.terminology_id
    );
    console.log("terminology_id1", terminologies1.terminology_id);
    console.log("terminology_id2", terminologies2.terminology_id);
    console.log(
      "assessment_type_id",
      terminologies1.assessment_type_id !== terminologies2.assessment_type_id
    );
    console.log("term", terminologies1.term !== terminologies2.term);
    console.log("value", terminologies1.value !== terminologies2.value);

    if (
      terminologies1.terminology_id !== terminologies2.terminology_id ||
      terminologies1.assessment_type_id !== terminologies2.assessment_type_id ||
      terminologies1.term !== terminologies2.term ||
      terminologies1.value !== terminologies2.value
    ) {
      return false;
    }
  }

  return true;
};
