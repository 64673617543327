// Utility function to get a contrasting color
export const getContrastingColor = (color: string) => {
  if (color === null) return "#000000";

  // Remove '#' if it exists at the beginning of the color string
  const sanitizedColor = color.startsWith("#") ? color.substring(1) : color;

  const isLight = (hexColor: string) => {
    const r = parseInt(hexColor.substring(1, 3), 16);
    const g = parseInt(hexColor.substring(3, 5), 16);
    const b = parseInt(hexColor.substring(5, 7), 16);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186;
  };
  return isLight(sanitizedColor) ? "#000000" : "#FFFFFF";
};
