import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { AxiosError } from "axios";
import { ICompany } from "../../interfaces/company.interface";

export interface CompanyState {
  loading: boolean;
  error: string | null;
  company: ICompany | null;
  companyList: ICompany[];
}

const initialState: CompanyState = {
  loading: false,
  error: null,
  company: null,
  companyList: [],
};

export const createCompany = createAsyncThunk(
  "company/create",
  async (data: ICompany, { rejectWithValue }) => {
    try {
      const response = await axios.post("/companies", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateCompany = createAsyncThunk(
  "company/update",
  async (data: ICompany, { rejectWithValue }) => {
    try {
      const response = await axios.put("/companies/" + data.company_id, data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchCompany = createAsyncThunk(
  "company/fetch",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/companies/" + id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listCompany = createAsyncThunk(
  "company/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/companies");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveCompany = createAsyncThunk(
  "company/archive",
  async (data: ICompany, { rejectWithValue }) => {
    try {
      const response = await axios.delete("/companies/" + data.company_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompany: (state, { payload }) => {
      state.company = payload;
    },
  },
  extraReducers: (builder) => {
    //Create
    builder.addCase(createCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.company = payload;
    });
    builder.addCase(createCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Update
    builder.addCase(updateCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.company = payload;
    });
    builder.addCase(updateCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Fetch
    builder.addCase(fetchCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.company = payload;
    });
    builder.addCase(fetchCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //List
    builder.addCase(listCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyList = payload;
    });
    builder.addCase(listCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Archive
    builder.addCase(archiveCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveCompany.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.company = payload;
    });
    builder.addCase(archiveCompany.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setCompany } = companySlice.actions;
export default companySlice.reducer;
