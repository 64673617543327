import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/portal/login.component";
import Home from "./components/home/home.component";
import ChooseCategory from "./components/assessments/user/chooseCategory.component";
import NotFound from "./components/exception/notFound.component";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/store";
import Question from "./components/assessments/user/question.component";
import Summary from "./components/assessments/user/summary.component";
import ActionAndTask from "./components/assessments/user/actionAndTask.component";
import Companies from "./components/companies/companies.component";
import CompanyForm from "./components/companies/companyForm.component";
import SignUp from "./components/portal/signup.component";
import Roles from "./components/roles/roles.component";
import RoleForm from "./components/roles/roleForm.component";
import AssessmentType from "./components/assessments/admin/assessmentType.component";
import NoviCallback from "./components/portal/noviCallback.component";
import AssessmentTypeForm from "./components/assessments/admin/assessmentTypeForm.component";
import { getLoggedInUser } from "./store/auth/auth.slice";
import { useSyncSession } from "./hooks/syncSession.hook";
import Profile from "./components/profile/profile.component";
import Success from "./components/assessments/user/success.component";
import PastAssessments from "./components/assessments/user/pastAssessments.component";
import EpubViewer from "./components/info/epub.component";

const App: React.FC = () => {
  const session = useSelector((state: RootState) => state.auth.session);
  const user = useSelector((state: RootState) => state.auth.user);
  const dispatch = useDispatch<AppDispatch>();

  useSyncSession();

  //TODO: consider creating a hook for this
  useEffect(() => {
    if (session && session.user_id) {
      dispatch(getLoggedInUser(session.user_id));
    }
  }, [session, dispatch]);

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={session ? <Home /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/epub"
          element={session ? <EpubViewer /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/assessment/category"
          element={
            session ? <ChooseCategory /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/assessment/summary"
          element={session ? <Summary /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/assessment/success"
          element={session ? <Success /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/assessment/summary/action-and-task"
          element={
            session ? <ActionAndTask /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/assessment/category/take"
          element={session ? <Question /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/past-assessments"
          element={
            session ? <PastAssessments /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/assessment-type"
          element={
            session ? <AssessmentType /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/assessment-type/form"
          element={
            session ? <AssessmentTypeForm /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/company"
          element={session ? <Companies /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/company/form"
          element={session ? <CompanyForm /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/profile"
          element={session ? <Profile /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/role"
          element={session ? <Roles /> : <Navigate to="/login" replace />}
        />
        <Route
          path="/role/form"
          element={session ? <RoleForm /> : <Navigate to="/login" replace />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/auth/callback" element={<NoviCallback />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </div>
  );
};

export default App;
