import React, { useState, useEffect, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaPlus } from "react-icons/fa";
import { LiaBrailleSolid } from "react-icons/lia";
import { MdDelete } from "react-icons/md";
import {
  IOption,
  IQuestion,
  ITerminology,
} from "../../../interfaces/assessment.interface";
import ColorPicker from "../../templates/colorPicker.component";
import { getTerm } from "../../../util/terminology.util";
import { getContrastingColor } from "../../../util/color.util";
import OptionsForm from "./optionsForm.component";
import { deepEqualOptions } from "../../../util/option.util";

// TODO: add required field here

interface QuestionsFormProps {
  questions: IQuestion[] | null;
  terminologies: ITerminology[] | null;
  categoryId: string;
  color: string;
  onQuestionsChange: (questions: IQuestion[]) => void;
}

const QuestionsForm: React.FC<QuestionsFormProps> = ({
  questions,
  terminologies,
  categoryId,
  color,
  onQuestionsChange,
}) => {
  const [localQuestions, setLocalQuestions] = useState<IQuestion[]>(
    questions ?? []
  );

  useEffect(() => {
    onQuestionsChange(localQuestions);
  }, [localQuestions, onQuestionsChange]);

  const handleAddQuestion = () => {
    const newQuestion: IQuestion = {
      question_id: "",
      category_id: categoryId,
      text: "",
      type: "multiple",
      required: true,
      order: localQuestions.length + 1,
      options: [],
      response: {
        response_id: "",
        assessment_id: "",
        category_id: "",
        question_id: "",
        option_id: "",
        answer: "",
      },
    };
    const updatedQuestions = [...localQuestions, newQuestion];

    // Update the order attribute for all criteria
    const reorderedQuestions = updatedQuestions.map((question, index) => ({
      ...question,
      order: index + 1,
    }));

    setLocalQuestions(reorderedQuestions);
  };

  const handleRemoveQuestion = (index: number) => {
    const updatedQuestions = localQuestions.filter((_, i) => i !== index);
    setLocalQuestions(updatedQuestions);
  };

  const handleChange = (
    index: number,
    field: string,
    value: string | number | boolean | IOption[]
  ) => {
    const updatedQuestions = localQuestions.map((c, i) => {
      if (i === index) {
        return { ...c, [field]: value };
      }
      return c;
    });
    setLocalQuestions(updatedQuestions);
  };

  const moveQuestion = (dragIndex: number, hoverIndex: number) => {
    const updatedQuestions = [...localQuestions];
    const dragQuestions = updatedQuestions[dragIndex];
    updatedQuestions.splice(dragIndex, 1);
    updatedQuestions.splice(hoverIndex, 0, dragQuestions);

    // Update the order attribute for all criteria
    const reorderedQuestions = updatedQuestions.map((category, index) => ({
      ...category,
      order: index + 1,
    }));

    setLocalQuestions(reorderedQuestions);
  };

  return (
    <div className="my-2 ">
      <div>
        {/* <DndProvider backend={HTML5Backend}> */}
        {localQuestions.length > 0 ? (
          <div className="space-y-4">
            {localQuestions.map((question, index) => (
              <QuestionItem
                key={index}
                index={index}
                question={question}
                color={color}
                terminologies={terminologies as ITerminology[]}
                onChange={handleChange}
                onRemove={handleRemoveQuestion}
                moveQuestion={moveQuestion}
              />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500">
            No {getTerm("questions", terminologies as ITerminology[])}{" "}
            available.
          </div>
        )}
        {/* </DndProvider> */}
      </div>
      <button
        onClick={handleAddQuestion}
        className="mt-2 bg-none border-2 text-white py-2 px-4 rounded-md w-full"
      >
        <p className="flex justify-center text-cloc-blue text-bold text-sm">
          <span className="my-auto mx-1">
            <FaPlus />
          </span>
          Add {getTerm("Question", terminologies as ITerminology[])}
        </p>
      </button>
    </div>
  );
};

interface QuestionItemProps {
  question: IQuestion;
  terminologies: ITerminology[];
  index: number;
  color: string;
  onChange: (
    index: number,
    field: string,
    value: string | number | boolean | IOption[]
  ) => void;
  onRemove: (index: number) => void;
  moveQuestion: (dragIndex: number, hoverIndex: number) => void;
}

const QuestionItem: React.FC<QuestionItemProps> = ({
  question,
  terminologies,
  index,
  color,
  onChange,
  onRemove,
  moveQuestion,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "QUESTION",
    item: { type: "QUESTION", index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "QUESTION",
    hover(item: { type: string; index: number }) {
      if (item.index === index) {
        return;
      }
      moveQuestion(item.index, index);
      item.index = index;
    },
  });

  const prevOptionsRef = useRef<IOption[]>(question.options || []);

  const handleTypeChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    if (value !== "multiple") {
      onChange(index, "options", []);
    }
    onChange(index, name, value);
  };

  const handleOptionsChange = (updatedOptions: IOption[]) => {
    // Check if updated
    if (!deepEqualOptions(updatedOptions, prevOptionsRef.current)) {
      console.log("Options CHANGED");
      prevOptionsRef.current = updatedOptions;
      onChange(index, "options", updatedOptions);
    }
  };

  const contrastingColor = getContrastingColor(color);

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="mb-4 p-4 border rounded-md flex flex-row"
      style={{ backgroundColor: color ?? "#ffffff", color: contrastingColor }}
    >
      <div className="my-auto w-10">
        <LiaBrailleSolid className="text-2xl" />
      </div>
      <div className="my-auto w-full">
        <div className="flex justify-between">
          <div className="flex my-2 mx-1 w-2/3">
            {/* Question Text */}
            <input
              type="text"
              name="text"
              id="text"
              placeholder={getTerm("Question", terminologies)}
              value={question.text ?? getTerm("Question", terminologies)}
              onChange={(e) => onChange(index, "text", e.target.value)}
              className="mt-1 pr-2 py-2 block w-full border-b-2 rounded-none"
              style={{
                borderColor: contrastingColor,
                backgroundColor: color,
                color: contrastingColor,
              }}
            />
          </div>
          {/* Required */}
          <div className="flex justify-start items-center my-auto px-4">
            <input
              name="required"
              id="required"
              type="checkbox"
              checked={question.required}
              onChange={(e) => onChange(index, "required", e.target.checked)}
            />
            <label className="ml-2 text-sm" htmlFor="required">
              Required
            </label>
          </div>
          {/* Question Type */}
          <div className="my-2 mx-1 flex">
            <label
              htmlFor="type"
              className="block mx-2 my-auto text-left text-sm font-medium"
              style={{ color: contrastingColor }}
            >
              Type
            </label>
            <select
              id="type"
              name="type"
              value={question.type}
              onChange={handleTypeChange}
              className="mt-1 p-2 block w-full border rounded-md"
              style={{
                borderColor: contrastingColor,
                backgroundColor: color,
                color: contrastingColor,
              }}
            >
              <option value="multiple">Multiple</option>
              <option value="free">Free Text</option>
              <option value="number">Number</option>
            </select>
          </div>
          {/* Remove Question */}
          <button
            onClick={() => onRemove(index)}
            className="mx-2 my-auto text-white rounded-md"
            style={{ color: contrastingColor }}
          >
            <p className="flex justify-center text-bold text-base">
              <span className="my-auto mx-1">
                <MdDelete
                  className="text-2xl"
                  style={{ color: contrastingColor }}
                />
              </span>
            </p>
          </button>
        </div>
        {/* Answer Type */}
        {question.type === "multiple" ? (
          // Option
          <div>
            <OptionsForm
              options={question.options}
              terminologies={terminologies}
              questionId={question.question_id}
              color={color}
              onOptionsChange={handleOptionsChange}
            ></OptionsForm>
          </div>
        ) : null}
        {question.type === "free" ? (
          // Free Text
          <div>
            <div>
              <textarea
                id="option"
                name="option"
                placeholder="Free Text"
                readOnly={true}
                disabled={true}
                className="mt-1 pr-2 py-2 block w-full border-b-2 rounded-none"
                style={{
                  borderColor: contrastingColor,
                  backgroundColor: color,
                  color: contrastingColor,
                }}
              />
            </div>
          </div>
        ) : null}
        {question.type === "number" ? (
          // Number
          <div>
            <input
              type="number"
              name="number"
              id="number"
              placeholder="Number"
              readOnly={true}
              disabled={true}
              className="mt-1 pr-2 py-2 block w-full border-b-2 rounded-none"
              style={{
                borderColor: contrastingColor,
                backgroundColor: color,
                color: contrastingColor,
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default QuestionsForm;
