import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import {
  IAssessment,
  ICategory,
  ICategoryAverage,
  ICategoryAveragesResult,
  ICriterion,
  IWeight,
} from "../../../interfaces/assessment.interface";
import { finishAssessment } from "../../../store/assessments/assessment.slice";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartItem,
  ChartOptions,
  ChartData,
} from "chart.js";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { setCategory } from "../../../store/assessments/assessment.slice";
import { getNextCategory } from "../../../util/categoryPagination.util";
import Header from "../../templates/header.component";
import { evaluateScore } from "../../../util/criteriaEvaluator.util";
import ProgressBar from "../../templates/progressBar.component";
import {
  calculateAverageOfAnsweredCategories,
  calculateCategoryAverages,
  calculateOverallAverage,
  hasCustomWeight,
} from "../../../util/assessmentScore.util";
import { FaSave } from "react-icons/fa";
import { setBreadcrumbs } from "../../../store/app/breadcrumbs.slice";
import Breadcrumb from "../../templates/breadcrumb.component";
// Register the necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Summary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const assessment: IAssessment = useSelector(
    (state: RootState) => state.assessment.customAssessment as IAssessment
  );
  const origAssessment: IAssessment = useSelector(
    (state: RootState) => state.assessment.assessment as IAssessment
  );
  const category: ICategory = useSelector(
    (state: RootState) => state.assessment.category as ICategory
  );
  const [isWeighted, setIsWeighted] = useState(false);
  const [overallAverage, setOverallAverage] = useState(0);
  const [
    overallAverageOfAnsweredCategories,
    setOverallAverageOfAnsweredCategories,
  ] = useState(0);
  const [averageValuesPerCategory, setAverageValuesPerCategory] =
    useState<ICategoryAveragesResult>(
      calculateCategoryAverages(assessment, origAssessment) ?? {
        averages: [],
        numberOfAnsweredCategories: 0,
        totalNumberOfCategories: 0,
      }
    );

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { name: "Home", path: "/" },
        {
          name: assessment.assessment_type!.name,
          path: "/assessment/category",
        },
        { name: "Summary", path: "/assessment/category/summary" },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    if (!assessment) {
      navigate("/");
    }
  }, [assessment, navigate]);

  useEffect(() => {
    if (!isWeighted) {
      setOverallAverage(calculateOverallAverage(averageValuesPerCategory));
      setOverallAverageOfAnsweredCategories(
        calculateAverageOfAnsweredCategories(averageValuesPerCategory)
      );
    } else {
      setOverallAverage(
        calculateOverallAverage(averageValuesPerCategory, assessment.weights)
      );
      setOverallAverageOfAnsweredCategories(
        calculateAverageOfAnsweredCategories(
          averageValuesPerCategory,
          assessment.weights
        )
      );
    }

    console.log("averageValuesPerCategory", averageValuesPerCategory);
    console.log("overallAverage", overallAverage);
    console.log(
      "overallAverageOfAnsweredCategories",
      overallAverageOfAnsweredCategories
    );
  }, [isWeighted, navigate]);

  const goToCategories = () => {
    // dispatch(setAssess(assess));
    navigate("/assessment/category");
  };

  const goToActionAndTask = () => {
    // dispatch(setActionAndTask(action));
    navigate("/assessment/summary/action-and-task");
  };

  const goToQuestion = () => {
    // dispatch(setActionAndTask(action));
    navigate("/assessment/category/take");
  };

  const finish = (assessment: IAssessment) => {
    dispatch(
      finishAssessment({
        id: assessment.assessment_id as string,
        score: calculateOverallAverage(averageValuesPerCategory),
        weighted_score: calculateOverallAverage(
          averageValuesPerCategory,
          assessment.weights
        ),
      })
    ).then(() => {
      // TODO: Handle negative flow
      navigate("/assessment/success");
    });
  };

  const colors = ["#24588c", "#3d8ec4", "#db5b44", "#de7e59"];

  const chartData: ChartData<"bar"> = {
    // labels: Object.keys(averageValuesPerCategory.averages),
    labels: averageValuesPerCategory.averages.map((average) => average.name),
    datasets: [
      {
        label: "Category Averages",
        // data: Object.values(averageValuesPerCategory.averages),
        data: averageValuesPerCategory.averages.map((average) => average.value),
        backgroundColor: averageValuesPerCategory.averages.map(
          (_, index) => colors[index % colors.length]
        ),
        borderColor: averageValuesPerCategory.averages.map(
          (_, index) => colors[index % colors.length]
        ),
        borderWidth: 1,
      },
    ],
  };

  const chartOptions: ChartOptions<"bar"> = {
    indexAxis: "y",
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const nextCategory = getNextCategory(category, assessment);
  const goToCategory = (cat: ICategory, prev: boolean) => {
    if (!prev) {
      // dispatch(setCategory(null));
      dispatch(setCategory(cat));
    }
    navigate("/assessment/category/take");
  };

  return (
    <div className="">
      <Header />
      <div>
        <div className="px-4">
          <Breadcrumb />
        </div>
        <div className="px-4 pb-2 text-left flex flex-row justify-between">
          <div>
            {/* <button className="px-4 py-2 mr-3" onClick={() => save(true)}>
          <IoChevronBack className="h-6 w-6" />
        </button> */}
            <p className="text-3xl font-bold">
              {assessment.assessment_type?.name} Summary
            </p>
          </div>
          <div className="flex my-auto justify-end">
            {hasCustomWeight(assessment) ? (
              <div className="flex justify-start items-center my-auto px-4">
                <input
                  name="selectAll"
                  id="selectAll"
                  type="checkbox"
                  checked={isWeighted}
                  onChange={(e) => setIsWeighted(e.target.checked)}
                />
                <label className="ml-2 text-lg" htmlFor="selectAll">
                  Show Weighted
                </label>
              </div>
            ) : null}
            <button
              className="px-4 py-2 bg-cloc-blue text-white rounded-full mr-3"
              onClick={() => goToActionAndTask()}
            >
              Action and Tasks
            </button>
            {/* <button
            className="px-4 py-2 bg-cloc-blue text-white rounded-full mr-3"
            onClick={() => goToQuestion()}
          >
            Edit Assessment
          </button> */}
            {/* <button
            className="px-4 py-2 bg-cloc-orange text-white rounded-full mr-3"
            onClick={() => finish(assessment)}
          >
            Finish
          </button> */}
          </div>
        </div>
      </div>

      <div className="mx-4 p-8 border-t border-cloc-blue flex justify-between pt-1 pb-2 h-full">
        <div className="p-4 bg-white shadow-md rounded-lg w-full">
          <Bar data={chartData} options={chartOptions} />
        </div>
        <div className="grid grid-cols-1 gap-2 px-4 w-full flex-col flex justify-evenly">
          {/* Card 1 */}
          <div className="p-4 bg-white shadow-md rounded-lg w-full h-56">
            <div className="pl-5 flex flex-column pt-2 pb-6">
              <div className="w-1/2 text-left">
                <p className="text-3xl font-medium">
                  Organization score based on areas assessed{" "}
                  <span className="font-bold">
                    ({averageValuesPerCategory.numberOfAnsweredCategories}/
                    {averageValuesPerCategory.totalNumberOfCategories})
                  </span>
                </p>
              </div>
              <div className="text-right w-1/2 px-5 flex justify-end">
                <div className="flex-col flex my-auto">
                  <p className="text-4xl font-bold text-cloc-orange mx-auto">
                    {overallAverageOfAnsweredCategories.toFixed(2)}
                  </p>
                  <p className="font-base text-sm mx-auto">
                    {
                      evaluateScore(
                        overallAverageOfAnsweredCategories,
                        assessment.assessment_type?.criteria as ICriterion[]
                      )?.name
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="px-5">
              <ProgressBar
                value={overallAverageOfAnsweredCategories}
                criteria={assessment.assessment_type?.criteria as ICriterion[]}
              />
            </div>
          </div>
          {/* Card 2 */}
          <div className="p-4 bg-white shadow-md rounded-lg w-full h-56">
            <div className="pl-5 flex flex-column pt-2 pb-6">
              <div className="w-1/2 text-left">
                <p className="text-3xl font-medium">
                  Organization score based on all functional areas
                </p>
              </div>
              <div className="text-right w-1/2 px-5 flex justify-end">
                <div className="flex-col flex my-auto">
                  <p className="text-4xl font-bold text-cloc-orange mx-auto">
                    {overallAverage.toFixed(2)}
                  </p>
                  <p className="font-base text-sm mx-auto">
                    {
                      evaluateScore(
                        overallAverage,
                        assessment.assessment_type?.criteria as ICriterion[]
                      )?.name
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="px-5">
              <ProgressBar
                value={overallAverage}
                criteria={assessment.assessment_type?.criteria as ICriterion[]}
              />
            </div>
          </div>
        </div>
      </div>

      {assessment.assessment_type?.type === "scoring" ? (
        <div className="flex justify-between px-4 pt-4 pb-8">
          {category ? (
            <div className="flex">
              <button
                className="flex text-sm px-4 py-2 bg-cloc-blue text-white rounded-full hover:bg-cloc-blue-alt transition-colors"
                onClick={() => goToCategory(category, true)}
              >
                <IoChevronBack className="my-auto mr-2 h-4 w-4" />
                Previous
              </button>
              <p className="text-xs my-auto pl-3">{category.name}</p>
            </div>
          ) : (
            // Empty div to align next button
            <div></div>
          )}
          <button
            className="flex text-sm text-sm px-4 py-2 bg-cloc-orange text-white rounded-full hover:bg-cloc-orange-alt transition-colors"
            onClick={() => finish(assessment)}
          >
            Finish
            <FaSave className="my-auto ml-2 h-4 w-4" />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Summary;
