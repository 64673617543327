import {
  IAssessment,
  ICategoryAverage,
  ICategoryAveragesResult,
  IWeight,
} from "../interfaces/assessment.interface";

export const getWeight = (
  category_id: string,
  weights: IWeight[] | undefined
): number => {
  if (!weights) {
    return 1.0; // Default weight if weights array is null or undefined
  }

  const weight = weights.find((weight) => weight.category_id === category_id);
  return weight ? parseFloat(weight.value.toFixed(2)) : 1.0;
};

export const calculateCategoryAverages = (
  assessment: IAssessment,
  origAssessment?: IAssessment
): ICategoryAveragesResult => {
  let categoryAverages: ICategoryAverage[] = [];
  let numberOfAnsweredCategories = 0;
  let totalNumberOfCategories = 0;

  assessment.assessment_type?.categories.forEach((category) => {
    let sum = 0;
    let answeredQuestions = 0;

    category.questions.forEach((question) => {
      if (question.response && question.response.option_id) {
        const option = question.options.find(
          (opt) => opt.option_id === question.response.option_id
        );
        if (option) {
          sum += option.value as number;
          answeredQuestions++;
        }
      }
    });

    if (answeredQuestions > 0) {
      categoryAverages.push({
        name: category.name,
        category_id: category.category_id,
        value: sum / answeredQuestions,
      });
      numberOfAnsweredCategories++;
    } else {
      categoryAverages.push({
        name: category.name,
        category_id: category.category_id,
        value: 0,
      }); // Include categories with 0 average if no questions were answered
    }
  });

  if (origAssessment)
    totalNumberOfCategories =
      origAssessment.assessment_type?.categories.length ?? 0;
  else
    totalNumberOfCategories =
      assessment.assessment_type?.categories.length ?? 0;

  return {
    averages: categoryAverages,
    numberOfAnsweredCategories,
    totalNumberOfCategories: totalNumberOfCategories,
  };
};

export const calculateOverallAverage = (
  categoryAveragesResult: ICategoryAveragesResult,
  weights?: IWeight[]
): number => {
  const { averages, totalNumberOfCategories } = categoryAveragesResult;

  // Calculate total sum considering weights
  let totalSum = 0;
  averages.forEach((average) => {
    const weight = getWeight(average.category_id, weights);
    totalSum += average.value * weight;
  });

  return totalNumberOfCategories > 0 ? totalSum / totalNumberOfCategories : 0;
};

export const calculateAverageOfAnsweredCategories = (
  categoryAveragesResult: ICategoryAveragesResult,
  weights?: IWeight[]
): number => {
  const { averages, numberOfAnsweredCategories } = categoryAveragesResult;

  // Calculate sum of answered categories considering weights
  let answeredCategoriesSum = 0;
  averages.forEach((average) => {
    if (average.value > 0) {
      const weight = getWeight(average.category_id, weights);
      answeredCategoriesSum += average.value * weight;
    }
  });

  return numberOfAnsweredCategories > 0
    ? answeredCategoriesSum / numberOfAnsweredCategories
    : 0;
};

export const hasCustomWeight = (assessment: IAssessment) => {
  let total = 0;
  if (!assessment.weights) return total;
  assessment.weights.forEach((weight) => (total += weight.value ?? 0));
  return total / (assessment.weights?.length ?? 0) > 1.0 ? true : false;
};

export const getCategoryAverage = (
  category_id: string,
  averageValuesPerCategory: ICategoryAveragesResult
) => {
  if (!averageValuesPerCategory.averages) {
    return 0;
  }

  const categoryAverage = averageValuesPerCategory.averages.find(
    (categoryAverage) => categoryAverage.category_id === category_id
  );
  return categoryAverage ? parseFloat(categoryAverage.value.toFixed(2)) : 0;
};
