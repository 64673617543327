import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  ICompany,
  IIndustry,
  IOrganizationSize,
  ICompanyReach,
  ITeamSize,
  IGeography,
} from "../../interfaces/company.interface";
import {
  createIndustry,
  listIndustry,
} from "../../store/company/industry.slice";
import { useNavigate } from "react-router-dom";
import { OnChangeValue, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { listOrganizationSize } from "../../store/company/organizationSize.slice";
import { listCompanyReach } from "../../store/company/companyReach.slice";
import {
  createTeamSize,
  listTeamSize,
} from "../../store/company/teamSize.slice";
import { listGeography } from "../../store/company/geography.slice";
import Select from "react-select/dist/declarations/src/Select";
import {
  createCompany,
  setCompany,
  updateCompany,
} from "../../store/company/company.slice";
import Header from "../templates/header.component";

interface SelectOption {
  label: string;
  value: string;
}

const CompanyForm: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const {
    company,
    industryList,
    organizationSizeList,
    companyReachList,
    teamSizeList,
    geographyList,
  } = useSelector((state: RootState) => ({
    company: state.company.company,
    industryList: state.industry.industryList,
    organizationSizeList: state.organizationSize.organizationSizeList,
    companyReachList: state.companyReach.companyReachList,
    teamSizeList: state.teamSize.teamSizeList,
    geographyList: state.geography.geographyList,
  }));
  const [selectedIndustry, setSelectedIndustry] = useState<SelectOption | null>(
    null
  );
  const [selectedTeamSize, setSelectedTeamSize] = useState<SelectOption | null>(
    null
  );
  // const [
  //   selectedOrganizationSize,
  //   setSelectedOrganizationSize,
  // ] = useState<SelectOption | null>(null);
  // const [
  //   selectedCompanyReach,
  //   setSelectedCompanyReach,
  // ] = useState<SelectOption | null>(null);
  // const [
  //   selectedGeography,
  //   setSelectedGeography,
  // ] = useState<SelectOption | null>(null);
  // const [company, setCompany] = useState<ICompany>({
  //   name: "",
  //   description: "",
  // });

  useEffect(() => {
    dispatch(listIndustry());
    dispatch(listOrganizationSize());
    dispatch(listCompanyReach());
    dispatch(listTeamSize());
    dispatch(listGeography());
  }, [dispatch]);

  //Creatable Dropdowns
  const handleCreateTeamSize = async (inputValue: string) => {
    const newTeamSize = await dispatch(
      createTeamSize({ name: inputValue })
    ).unwrap();
    setSelectedTeamSize({
      value: newTeamSize.team_size_id,
      label: newTeamSize.name,
    });
    dispatch(listTeamSize()); // Refresh the list to include the newly added team size
  };

  const handleCreateIndustry = async (inputValue: string) => {
    const newIndustry = await dispatch(
      createIndustry({ name: inputValue })
    ).unwrap();
    setSelectedIndustry({
      value: newIndustry.industry_id,
      label: newIndustry.name,
    });
    dispatch(listIndustry());
  };

  //Options
  const industryOptions: SelectOption[] = industryList.map((industry) => ({
    value: industry.industry_id!,
    label: industry.name!,
  }));

  const teamSizeOptions: SelectOption[] = teamSizeList.map((size) => ({
    value: size.team_size_id!,
    label: size.name!,
  }));

  // const organizationSizeOptions: SelectOption[] = organizationSizeList.map(
  //   (size) => ({ value: size.organization_size_id!, label: size.name! })
  // );
  // const organizationSizeOptions: SelectOption[] = organizationSizeList.map(
  //   (size) => ({
  //     value: size.organization_size_id?.toString() as string,
  //     label: size.name as string,
  //   })
  // );

  // const companyReachOptions: SelectOption[] = companyReachList.map((reach) => ({
  //   value: reach.company_reach_id!,
  //   label: reach.name!,
  // }));

  // const geographyOptions: SelectOption[] = geographyList.map((geo) => ({
  //   value: geo.geography_id!,
  //   label: geo.name!,
  // }));

  //Dropdown Change
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    dispatch(setCompany({ ...company, [name]: value }));
  };

  const handleIndustryChange = (
    newValue: OnChangeValue<SelectOption, false>
  ) => {
    dispatch(setCompany({ ...company, ["industry_id"]: newValue?.value }));
    setSelectedIndustry(newValue);
  };

  const handleTeamSizeChange = (
    newValue: OnChangeValue<SelectOption, false>
  ) => {
    dispatch(setCompany({ ...company, ["team_size_id"]: newValue?.value }));
    setSelectedTeamSize(newValue);
  };

  // const handleInputChange = (
  //   e: React.ChangeEvent<
  //     HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
  //   >
  // ) => {
  //   const { name, value } = e.target;
  //   setCompany({ ...company, [name]: value });
  // };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("company", company);
    if (company?.company_id) {
      dispatch(updateCompany(company as ICompany)).then(() => {
        //add success modal for success
        //catch error
        dispatch(setCompany(null));
        navigate("/company");
      });
    } else {
      dispatch(createCompany(company as ICompany)).then(() => {
        //add success modal for success
        //catch error
        dispatch(setCompany(null));
        navigate("/company");
      });
    }
    // if company_id is null, create
    // if company_id, update
    // Dispatch create or update action based on whether company.company_id is set
  };

  const goBack = () => {
    dispatch(setCompany(null));
    navigate("/company");
  };

  useEffect(() => {
    if (company) {
      const currentIndustry = industryList.find(
        (industry) => industry.industry_id === company.industry_id
      );
      if (currentIndustry) {
        setSelectedIndustry({
          value: currentIndustry.industry_id ?? "",
          label: currentIndustry.name ?? "",
        });
      }

      const currentTeamSize = teamSizeList.find(
        (size) => size.team_size_id === company.team_size_id
      );
      if (currentTeamSize) {
        setSelectedTeamSize({
          value: currentTeamSize.team_size_id ?? "",
          label: currentTeamSize.name ?? "",
        });
      }
    }
  }, [company, industryList, teamSizeList]);

  return (
    <form onSubmit={handleSubmit} className="p-4 space-y-4">
      <Header />
      <button
        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        onClick={() => goBack()}
      >
        Go Back
      </button>
      <div>
        <label htmlFor="name" className="block">
          Name
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={company?.name || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        />
      </div>
      <div>
        <label htmlFor="description" className="block">
          Description
        </label>
        <textarea
          id="description"
          name="description"
          value={company?.description || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        />
      </div>
      {/* Industry Select */}
      <div>
        <label htmlFor="industry" className="block">
          Industry
        </label>
        <CreatableSelect
          id="industry"
          value={selectedIndustry}
          onChange={handleIndustryChange}
          options={industryOptions}
          isClearable
          placeholder="Select or type to add..."
          onCreateOption={handleCreateIndustry}
        />
      </div>
      {/* Organization Size Select */}
      <div>
        <label htmlFor="organizationSize" className="block">
          Organization Size
        </label>
        {/* <Select
          id="organizationSize"
          value={selectedOrganizationSize}
          onChange={handleOrganizationSizeChange}
          options={organizationSizeOptions}
          isClearable
          placeholder="Select..."
        /> */}
        <select
          id="organization_size_id"
          name="organization_size_id"
          value={company?.organization_size_id || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        >
          <option value="">Select Organization Size</option>
          {organizationSizeList.map((item) => (
            <option
              key={item.organization_size_id}
              value={item.organization_size_id}
            >
              {item.name}
            </option>
          ))}
        </select>
      </div>
      {/* Company Reach Select */}
      <div>
        <label htmlFor="companyReach" className="block">
          Company Reach
        </label>
        {/* <Select
          id="companyReach"
          value={selectedCompanyReach}
          onChange={setSelectedCompanyReach}
          options={companyReachOptions}
          isClearable
          placeholder="Select..."
        /> */}
        <select
          id="company_reach_id"
          name="company_reach_id"
          value={company?.company_reach_id || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        >
          <option value="">Select Company Reach</option>
          {companyReachList.map((item) => (
            <option key={item.company_reach_id} value={item.company_reach_id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      {/* Team Size CreatableSelect */}
      <div>
        <label htmlFor="teamSize" className="block">
          Team Size
        </label>
        <CreatableSelect
          id="teamSize"
          value={selectedTeamSize}
          onChange={handleTeamSizeChange}
          options={teamSizeOptions}
          isClearable
          placeholder="Select or type to add..."
          onCreateOption={handleCreateTeamSize}
        />
      </div>
      {/* Geography Select */}
      <div>
        <label htmlFor="geography" className="block">
          Geography
        </label>
        {/* <Select
          id="geography"
          value={selectedGeography}
          onChange={setSelectedGeography}
          options={geographyOptions}
          isClearable
          placeholder="Select..."
        /> */}
        <select
          id="geography_id"
          name="geography_id"
          value={company?.geography_id || ""}
          onChange={handleInputChange}
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        >
          <option value="">Select Geography</option>
          {geographyList.map((item) => (
            <option key={item.geography_id} value={item.geography_id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      {/* Photo Upload Placeholder */}
      {/* <div>
        <label htmlFor="photo" className="block">
          Photo (optional)
        </label>
        <input
          type="file"
          id="photo"
          name="photo"
          onChange={handleInputChange} // Adjust this handler as needed
          className="mt-1 block w-full border-gray-300 shadow-sm rounded-md"
        />
      </div> */}
      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Save Company
      </button>
    </form>
  );
};

export default CompanyForm;
