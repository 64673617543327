import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { IUser } from "../../interfaces/auth.interface";
import { AxiosError, AxiosResponse } from "axios";
import { ICompany, IIndustry } from "../../interfaces/company.interface";

export interface IndustryState {
  loading: boolean;
  error: string | null;
  industry: IIndustry | null;
  industryList: IIndustry[];
}

const initialState: IndustryState = {
  loading: false,
  error: null,
  industry: null,
  industryList: [],
};

export const createIndustry = createAsyncThunk(
  "industry/create",
  async (data: IIndustry, { rejectWithValue }) => {
    try {
      const response = await axios.post("/industries", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateIndustry = createAsyncThunk(
  "industry/update",
  async (data: IIndustry, { rejectWithValue }) => {
    try {
      const response = await axios.put("/industries/" + data.industry_id, data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchIndustry = createAsyncThunk(
  "industry/fetch",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/industries/" + id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listIndustry = createAsyncThunk(
  "industry/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/industries");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveIndustry = createAsyncThunk(
  "industry/archive",
  async (data: IIndustry, { rejectWithValue }) => {
    try {
      const response = await axios.delete("/industries/" + data.industry_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const industrySlice = createSlice({
  name: "industry",
  initialState,
  reducers: {
    setIndustry: (state, { payload }) => {
      state.industry = payload;
    },
  },
  extraReducers: (builder) => {
    //Create
    builder.addCase(createIndustry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createIndustry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.industry = payload;
    });
    builder.addCase(createIndustry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Update
    builder.addCase(updateIndustry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateIndustry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.industry = payload;
    });
    builder.addCase(updateIndustry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Fetch
    builder.addCase(fetchIndustry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchIndustry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.industry = payload;
    });
    builder.addCase(fetchIndustry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //List
    builder.addCase(listIndustry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listIndustry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.industryList = payload;
    });
    builder.addCase(listIndustry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Archive
    builder.addCase(archiveIndustry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveIndustry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.industry = payload;
    });
    builder.addCase(archiveIndustry.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setIndustry } = industrySlice.actions;
export default industrySlice.reducer;
