import {
  IAssessmentType,
  ICriterion,
  ITerminology,
} from "../interfaces/assessment.interface";
import { deepEqualCategories } from "./category.util";
import { deepEqualCriteria } from "./criteriaEvaluator.util";
import { deepEqualTerminologies } from "./terminology.util";

export const deepEqualAssessmentType = (
  assessmentType1: IAssessmentType,
  assessmentType2: IAssessmentType
): boolean => {
  if (assessmentType1 === null || assessmentType2 === null) return false;

  if (
    assessmentType1.assessment_type_id !== assessmentType2.assessment_type_id ||
    assessmentType1.description !== assessmentType2.description ||
    assessmentType1.name !== assessmentType2.name ||
    assessmentType1.order !== assessmentType2.order ||
    assessmentType1.type !== assessmentType2.type ||
    !deepEqualCriteria(
      assessmentType1.criteria as ICriterion[],
      assessmentType2.criteria as ICriterion[]
    ) ||
    !deepEqualTerminologies(
      assessmentType1.terminologies as ITerminology[],
      assessmentType2.terminologies as ITerminology[]
    ) ||
    !deepEqualCategories(assessmentType1.categories, assessmentType2.categories)
  ) {
    return false;
  }

  return true;
};

export const deepEqualAssessmentTypes = (
  arr1: IAssessmentType[],
  arr2: IAssessmentType[]
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const assessmentType1 = arr1[i];
    const assessmentType2 = arr2[i];

    if (
      assessmentType1.assessment_type_id !==
        assessmentType2.assessment_type_id ||
      assessmentType1.description !== assessmentType2.description ||
      assessmentType1.name !== assessmentType2.name ||
      assessmentType1.order !== assessmentType2.order ||
      assessmentType1.type !== assessmentType2.type ||
      !deepEqualCriteria(
        assessmentType1.criteria as ICriterion[],
        assessmentType2.criteria as ICriterion[]
      ) ||
      !deepEqualTerminologies(
        assessmentType1.terminologies as ITerminology[],
        assessmentType2.terminologies as ITerminology[]
      ) ||
      !deepEqualCategories(
        assessmentType1.categories,
        assessmentType2.categories
      )
    ) {
      return false;
    }
  }

  return true;
};
