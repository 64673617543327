import React, { useState, useEffect, useRef } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaPlus } from "react-icons/fa";
import { LiaBrailleSolid } from "react-icons/lia";
import { MdDelete } from "react-icons/md";
import {
  ICategory,
  IQuestion,
  ITerminology,
} from "../../../interfaces/assessment.interface";
import ColorPicker from "../../templates/colorPicker.component";
import { getTerm } from "../../../util/terminology.util";
import QuestionsForm from "./questionsForm.component";
import { deepEqualQuestions } from "../../../util/question.util";
import { getContrastingColor } from "../../../util/color.util";
import { MultiBackend } from "react-dnd-multi-backend";

interface CategoriesFormProps {
  categories: ICategory[] | null;
  terminologies: ITerminology[] | null;
  assessmentTypeId: string;
  onCategoriesChange: (categories: ICategory[]) => void;
}

const CategoriesForm: React.FC<CategoriesFormProps> = ({
  categories,
  terminologies,
  assessmentTypeId,
  onCategoriesChange,
}) => {
  const [localCategories, setLocalCategories] = useState<ICategory[]>(
    categories ?? []
  );

  useEffect(() => {
    onCategoriesChange(localCategories);
  }, [localCategories, onCategoriesChange]);

  const handleAddCategory = () => {
    const newCategory: ICategory = {
      category_id: "",
      assessment_type_id: assessmentTypeId,
      name: "",
      description: "",
      color: "",
      order: localCategories.length + 1,
      questions: [],
    };
    const updatedCategories = [...localCategories, newCategory];

    // Update the order attribute for all criteria
    const reorderedCategories = updatedCategories.map((category, index) => ({
      ...category,
      order: index + 1,
    }));

    setLocalCategories(reorderedCategories);
  };

  const handleRemoveCategory = (index: number) => {
    const updatedCategories = localCategories.filter((_, i) => i !== index);
    setLocalCategories(updatedCategories);
  };

  const handleChange = (
    index: number,
    field: string,
    value: string | number | IQuestion[]
  ) => {
    const updatedCategories = localCategories.map((c, i) => {
      if (i === index) {
        return { ...c, [field]: value };
      }
      return c;
    });
    setLocalCategories(updatedCategories);
  };

  const moveCategory = (dragIndex: number, hoverIndex: number) => {
    const updatedCategories = [...localCategories];
    const dragCategories = updatedCategories[dragIndex];
    updatedCategories.splice(dragIndex, 1);
    updatedCategories.splice(hoverIndex, 0, dragCategories);

    // Update the order attribute for all criteria
    const reorderedCategories = updatedCategories.map((category, index) => ({
      ...category,
      order: index + 1,
    }));

    setLocalCategories(reorderedCategories);
  };

  return (
    <div className="my-2 ">
      <DndProvider backend={HTML5Backend}>
        {localCategories.length > 0 ? (
          <div className="space-y-4">
            {localCategories.map((category, index) => (
              <CategoryItem
                key={index}
                index={index}
                category={category}
                terminologies={terminologies as ITerminology[]}
                onChange={handleChange}
                onRemove={handleRemoveCategory}
                moveCategory={moveCategory}
              />
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-500">
            No {getTerm("category", terminologies as ITerminology[])} available.
          </div>
        )}
      </DndProvider>
      <button
        onClick={handleAddCategory}
        className="mt-2 bg-none border-2 text-white py-2 px-4 rounded-md w-full"
      >
        <p className="flex justify-center text-cloc-blue text-bold text-sm">
          <span className="my-auto mx-1">
            <FaPlus />
          </span>
          Add {getTerm("category", terminologies as ITerminology[])}
        </p>
      </button>
    </div>
  );
};

interface CategoryItemProps {
  category: ICategory;
  terminologies: ITerminology[];
  index: number;
  onChange: (
    index: number,
    field: string,
    value: string | number | IQuestion[]
  ) => void;
  onRemove: (index: number) => void;
  moveCategory: (dragIndex: number, hoverIndex: number) => void;
}

const CategoryItem: React.FC<CategoryItemProps> = ({
  category,
  terminologies,
  index,
  onChange,
  onRemove,
  moveCategory,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "CATEGORY",
    item: { type: "CATEGORY", index },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "CATEGORY",
    hover(item: { type: string; index: number }) {
      if (item.index === index) {
        return;
      }
      moveCategory(item.index, index);
      item.index = index;
    },
  });

  const prevQuestionRef = useRef<IQuestion[]>(category.questions || []);

  const handleQuestionsChange = (updatedQuestions: IQuestion[]) => {
    // Check if updated
    if (!deepEqualQuestions(updatedQuestions, prevQuestionRef.current)) {
      console.log("QUESTIONS CHANGED");
      prevQuestionRef.current = updatedQuestions;
      onChange(index, "questions", updatedQuestions);
    }
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="mb-4 p-4 border rounded-md flex flex-row"
    >
      <div className="my-auto w-10">
        <LiaBrailleSolid className="text-2xl text-gray-500" />
      </div>
      <div className="my-auto w-full">
        <div className="flex justify-between">
          <div className="flex my-2 mx-1 w-2/3">
            {/* Name */}
            <input
              type="text"
              name="name"
              id="name"
              placeholder={getTerm("Category", terminologies)}
              value={category.name ?? getTerm("Category", terminologies)}
              onChange={(e) => onChange(index, "name", e.target.value)}
              className="mt-1 pr-2 py-2 block w-full border-b-2 rounded-none"
              style={{ borderColor: category.color ?? "#000000" }}
            />
          </div>
          {/* Color */}
          <div className="my-2 mx-1 flex">
            <label
              htmlFor="color"
              className="block mx-2 my-auto text-left text-sm font-medium text-gray-700"
            >
              Color:
            </label>
            <ColorPicker
              initialColor={category.color}
              onColorChange={(color) => onChange(index, "color", color)}
            ></ColorPicker>
          </div>
          {/* Remove */}
          <button onClick={() => onRemove(index)} className="mt-2 py-1 px-4">
            <p className="flex justify-center text-bold text-base">
              <span className="my-auto mx-1">
                <MdDelete className="text-2xl text-gray-500" />
              </span>
            </p>
          </button>
        </div>
        <div>
          {/* Description */}
          <textarea
            id="description"
            name="description"
            placeholder="Description"
            value={category.description}
            onChange={(e) => onChange(index, "description", e.target.value)}
            className="mt-1 pr-2 py-2 block w-full border-b-2 rounded-none"
          />
        </div>
        {/* Question */}
        <div>
          <QuestionsForm
            questions={category.questions}
            terminologies={terminologies}
            categoryId={category.category_id}
            color={
              (category.color as string) != ""
                ? (category.color as string)
                : "#ffffff"
            }
            onQuestionsChange={handleQuestionsChange}
          ></QuestionsForm>
        </div>
      </div>
    </div>
  );
};

export default CategoriesForm;
