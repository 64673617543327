import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { AxiosError } from "axios";
import { IGeography } from "../../interfaces/company.interface";

export interface GeographyState {
  loading: boolean;
  error: string | null;
  geography: IGeography | null;
  geographyList: IGeography[];
}

const initialState: GeographyState = {
  loading: false,
  error: null,
  geography: null,
  geographyList: [],
};

export const createGeography = createAsyncThunk(
  "geography/create",
  async (data: IGeography, { rejectWithValue }) => {
    try {
      const response = await axios.post("/geographies", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateGeography = createAsyncThunk(
  "geography/update",
  async (data: IGeography, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "/geographies/" + data.geography_id,
        data
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchGeography = createAsyncThunk(
  "geography/fetch",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/geographies/" + id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listGeography = createAsyncThunk(
  "geography/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/geographies");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveGeography = createAsyncThunk(
  "geography/archive",
  async (data: IGeography, { rejectWithValue }) => {
    try {
      const response = await axios.delete("/geographies/" + data.geography_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const GeographySlice = createSlice({
  name: "geography",
  initialState,
  reducers: {
    setGeography: (state, { payload }) => {
      state.geography = payload;
    },
  },
  extraReducers: (builder) => {
    //Create
    builder.addCase(createGeography.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createGeography.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.geography = payload;
    });
    builder.addCase(createGeography.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Update
    builder.addCase(updateGeography.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateGeography.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.geography = payload;
    });
    builder.addCase(updateGeography.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Fetch
    builder.addCase(fetchGeography.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchGeography.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.geography = payload;
    });
    builder.addCase(fetchGeography.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //List
    builder.addCase(listGeography.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listGeography.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.geographyList = payload;
    });
    builder.addCase(listGeography.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Archive
    builder.addCase(archiveGeography.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveGeography.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.geography = payload;
    });
    builder.addCase(archiveGeography.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setGeography } = GeographySlice.actions;
export default GeographySlice.reducer;
