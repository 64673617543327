import axios from "axios";

//TODO: add env file to hide configs
const baseUrl = "https://cloc-assessments-nc5l5.kinsta.app/api/v1";
// const baseUrl = "http://localhost:8000/api/v1";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to refresh token on receiving a 401 error
instance.interceptors.response.use(
  (response) => response, // simply return the response for successful requests
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        // Assuming the refresh token is stored alongside the access token in local storage
        const session = JSON.parse(localStorage.getItem("session") as string);
        const refreshedSession = await axios.post(
          baseUrl + "/users/refresh-token",
          {
            refresh_token: session.refresh_token,
          }
        );
        localStorage.setItem("token", refreshedSession.data.access_token);
        localStorage.setItem("session", JSON.stringify(refreshedSession.data));
        // Update the header with the new token and retry the original request
        originalRequest.headers["Authorization"] =
          "Bearer " + refreshedSession.data.access_token;
        return instance(originalRequest);
      } catch (refreshError) {
        console.log("Unable to refresh token:", refreshError);
        localStorage.removeItem("token");
        localStorage.removeItem("session");
        window.location.href = "/login"; // Redirect to login page or show login modal
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
