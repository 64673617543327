import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import {
  IAssessment,
  ICategory,
} from "../../../interfaces/assessment.interface";
import {
  clearAssessment,
  setCategory,
  setCustomAssessment,
} from "../../../store/assessments/assessment.slice";
import { IoChevronBack } from "react-icons/io5";
import Header from "../../templates/header.component";

const Success: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const goHome = () => {
    dispatch(clearAssessment());
    navigate("/");
  };

  return (
    <div className="bg-white">
      <div className="z-40 fixed w-screen">
        <Header />
      </div>
      <div className="h-screen flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold text-green-800 mb-0">Success!</h1>
        <p className="text-xl text-gray-600 mb-2">
          Assessment has been completed.
        </p>
        <div className=" grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
          <button
            className="mx-2 px-4 py-2 bg-cloc-blue text-white rounded-full hover:bg-cloc-blue-alt transition-colors"
            onClick={() => navigate("/assessment/category/summary")}
          >
            Summary
          </button>
          <button
            className="mx-2 px-4 py-2 bg-cloc-blue text-white rounded-full hover:bg-cloc-blue-alt transition-colors"
            onClick={() => navigate("/assessment/category/action-and-task")}
          >
            Actions and Tasks
          </button>
          <button
            className="mx-2 px-4 py-2 bg-cloc-orange text-white rounded-full hover:bg-cloc-orange-alt transition-colors"
            onClick={() => goHome()}
          >
            Go Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
