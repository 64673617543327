import React, { useState, useEffect, useRef } from "react";
import { EpubView, ReactReader } from "react-reader";
import ePub, { NavItem, Rendition } from "epubjs"; // Using 'epubjs' for EPUB parsing
import Header from "../templates/header.component";
import Breadcrumb from "../templates/breadcrumb.component";
import { setBreadcrumbs } from "../../store/app/breadcrumbs.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";

const EpubViewer: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [book, setBook] = useState<any>(null); // Holds the parsed EPUB book object

  const [location, setLocation] = useState<string | number>(0);
  const [page, setPage] = useState("");
  const rendition = useRef<Rendition | undefined>(undefined);
  const toc = useRef<NavItem[]>([]);

  const [currentLocation, setCurrentLocation] = useState<string>(""); // Current location (CFI) within the EPUB
  const epubFilePath =
    // process.env.PUBLIC_URL + "/famouspaintings.epub";
    process.env.PUBLIC_URL + "/alice.epub";
  // process.env.PUBLIC_URL + "/Sway.epub";
  // process.env.PUBLIC_URL + "/Around the World in 28 Languages.epub";
  // process.env.PUBLIC_URL + "/Core 12 Maturity Assessment Playbook 2.epub";
  // const epubFilePath = "https://react-reader.metabits.no/files/alice.epub";

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { name: "Home", path: "/" },
        {
          name: "e-Pub",
          path: "/epub",
        },
      ])
    );
  }, [dispatch]);

  useEffect(() => {
    // Parse EPUB and initialize the book object
    const parseEpub = async () => {
      const epubBook = await ePub(epubFilePath);
      setBook(epubBook);

      console.log("BOOK ON LOAD", epubBook);

      // Get initial location (usually the first page)
      const initialLocation = await epubBook.locations.generate(0); // Pass 0 or other appropriate value for 'chars'
      setCurrentLocation(initialLocation[0]); // Assuming initialLocation is an array, use [0] to get the first location
    };

    parseEpub();

    return () => {
      // Clean up if needed (e.g., close book object)
      if (book) {
        book.destroy();
      }
    };
  }, []);

  const changeLocation = (epubcfi: string) => {
    console.log("epubcfi", epubcfi);
    setLocation(epubcfi);
    if (rendition.current && toc.current) {
      const { displayed, href } = rendition.current.location.start;
      const chapter = toc.current.find((item) => item.href === href);
      setPage(
        `Page ${displayed.page} of ${displayed.total} in chapter ${
          chapter ? chapter.label : "n/a"
        }`
      );
    }
  };

  return (
    <div className="">
      <Header />
      <div>
        <div className="px-4">
          <Breadcrumb />
        </div>
        <div className="px-4 pb-2 text-left lg:flex lg:flex-row justify-between">
          {/* <div className="flex"> */}
          <div className="">
            {/* <button className="px-4 py-2 mr-3" onClick={() => goHome()}>
            <IoChevronBack className="h-6 w-6" />
          </button> */}
            <p className="text-xl md:text-3xl font-bold">e-Pub</p>
          </div>
          <div className="my-1 lg:my-auto flex justify-between lg:justify-end text-xs md:text-sm lg:text-base">
            {/*  */}
          </div>
        </div>
      </div>

      {/* Cards */}
      <div className="mx-4 p-2 md:p-8 border-t border-cloc-blue">
        <div>
          <p>{page}</p>
          <div style={{ height: "100vh", position: "relative" }}>
            {book && (
              <ReactReader
                url={epubFilePath}
                location={location}
                tocChanged={(_toc) => (toc.current = _toc)}
                locationChanged={(epubcfi: string) => changeLocation(epubcfi)}
                getRendition={(_rendition: Rendition) => {
                  rendition.current = _rendition;
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EpubViewer;
