import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { AxiosError } from "axios";
import { IRole } from "../../interfaces/auth.interface";

export interface RoleState {
  loading: boolean;
  error: string | null;
  role: IRole | null;
  roleList: IRole[];
}

const initialState: RoleState = {
  loading: false,
  error: null,
  role: null,
  roleList: [],
};

export const createRole = createAsyncThunk(
  "role/create",
  async (data: IRole, { rejectWithValue }) => {
    try {
      const response = await axios.post("/roles", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateRole = createAsyncThunk(
  "role/update",
  async (data: IRole, { rejectWithValue }) => {
    try {
      const response = await axios.put("/roles/" + data.role_id, data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchRole = createAsyncThunk(
  "role/fetch",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/roles/" + id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listRole = createAsyncThunk(
  "role/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/roles");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveRole = createAsyncThunk(
  "role/archive",
  async (data: IRole, { rejectWithValue }) => {
    try {
      const response = await axios.delete("/roles/" + data.role_id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const RoleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, { payload }) => {
      state.role = payload;
    },
  },
  extraReducers: (builder) => {
    //Create
    builder.addCase(createRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createRole.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.role = payload;
    });
    builder.addCase(createRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Update
    builder.addCase(updateRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRole.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.role = payload;
    });
    builder.addCase(updateRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Fetch
    builder.addCase(fetchRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchRole.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.role = payload;
    });
    builder.addCase(fetchRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //List
    builder.addCase(listRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listRole.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.roleList = payload;
    });
    builder.addCase(listRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Archive
    builder.addCase(archiveRole.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveRole.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.role = payload;
    });
    builder.addCase(archiveRole.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setRole } = RoleSlice.actions;
export default RoleSlice.reducer;
