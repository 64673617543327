import { IOption } from "../interfaces/assessment.interface";

export const deepEqualOptions = (arr1: IOption[], arr2: IOption[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    const options1 = arr1[i];
    const options2 = arr2[i];

    if (
      options1.option_id !== options2.option_id ||
      options1.question_id !== options2.question_id ||
      options1.text !== options2.text ||
      options1.order !== options2.order ||
      options1.detail !== options2.detail ||
      options1.value !== options2.value
    ) {
      return false;
    }
  }

  return true;
};
