import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/auth.slice";
import assessmentTypeReducer from "./assessments/assessmentType.slice";
import categoryReducer from "./assessments/category.slice";
import questionReducer from "./assessments/question.slice";
import optionReducer from "./assessments/option.slice";
import assessmentReducer from "./assessments/assessment.slice";
import companyReducer from "./company/company.slice";
import companyReachReducer from "./company/companyReach.slice";
import geographyReducer from "./company/geography.slice";
import industryReducer from "./company/industry.slice";
import organizationSizeReducer from "./company/organizationSize.slice";
import teamSizeReducer from "./company/teamSize.slice";
import roleReducer from "./auth/role.slice";
import breadcrumbsReducer from "./app/breadcrumbs.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    assessmentType: assessmentTypeReducer,
    category: categoryReducer,
    question: questionReducer,
    option: optionReducer,
    assessment: assessmentReducer,
    company: companyReducer,
    companyReach: companyReachReducer,
    geography: geographyReducer,
    industry: industryReducer,
    organizationSize: organizationSizeReducer,
    teamSize: teamSizeReducer,
    role: roleReducer,
    breadcrumbs: breadcrumbsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
