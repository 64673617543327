import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../api/axios";
import { AxiosError } from "axios";
import { ICompanyReach } from "../../interfaces/company.interface";

export interface CompanyReachState {
  loading: boolean;
  error: string | null;
  companyReach: ICompanyReach | null;
  companyReachList: ICompanyReach[];
}

const initialState: CompanyReachState = {
  loading: false,
  error: null,
  companyReach: null,
  companyReachList: [],
};

export const createCompanyReach = createAsyncThunk(
  "companyReach/create",
  async (data: ICompanyReach, { rejectWithValue }) => {
    try {
      const response = await axios.post("/company-reaches", data);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const updateCompanyReach = createAsyncThunk(
  "companyReach/update",
  async (data: ICompanyReach, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        "/company-reaches/" + data.company_reach_id,
        data
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const fetchCompanyReach = createAsyncThunk(
  "companyReach/fetch",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get("/company-reaches/" + id);
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const listCompanyReach = createAsyncThunk(
  "companyReach/list",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/company-reaches");
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const archiveCompanyReach = createAsyncThunk(
  "companyReach/archive",
  async (data: ICompanyReach, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        "/company-reaches/" + data.company_reach_id
      );
      return response.data;
    } catch (error) {
      if (!(error as AxiosError).response) {
        throw error;
      }
      return rejectWithValue(
        (error as AxiosError<{ message: string }>).response?.data?.message
      );
    }
  }
);

export const CompanyReachSlice = createSlice({
  name: "companyReach",
  initialState,
  reducers: {
    setCompanyReach: (state, { payload }) => {
      state.companyReach = payload;
    },
  },
  extraReducers: (builder) => {
    //Create
    builder.addCase(createCompanyReach.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCompanyReach.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyReach = payload;
    });
    builder.addCase(createCompanyReach.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Update
    builder.addCase(updateCompanyReach.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCompanyReach.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyReach = payload;
    });
    builder.addCase(updateCompanyReach.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Fetch
    builder.addCase(fetchCompanyReach.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCompanyReach.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyReach = payload;
    });
    builder.addCase(fetchCompanyReach.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //List
    builder.addCase(listCompanyReach.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listCompanyReach.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyReachList = payload;
    });
    builder.addCase(listCompanyReach.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    //Archive
    builder.addCase(archiveCompanyReach.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(archiveCompanyReach.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.companyReach = payload;
    });
    builder.addCase(archiveCompanyReach.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setCompanyReach } = CompanyReachSlice.actions;
export default CompanyReachSlice.reducer;
